import React, {useState, useEffect} from 'react';
import { Button, Grid, Typography, TextField, CircularProgress} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getAuth } from 'firebase/auth';
import { database } from '../firebase/firebase';
import { getDoc, doc, updateDoc, deleteDoc, setDoc, arrayUnion, collection, getDocs} from "firebase/firestore"; 
import {connect} from 'react-redux';
import { setUser, login } from '../actions/authUser';
import { useNavigate } from 'react-router-dom';
import { setClearActivateAccountInfo } from '../actions/activateAcc';
import {name, phone, } from '../util/appUtil';
import {setAllRecipes, setAllPrograms} from '../actions/programsRecipes';
import { setLoading } from '../actions/loading';

const MakeProfile = (props) => {
    const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [tel, setTel] = useState("");
	const [deliveryAddress, setDeliveryAddress] = useState("");
	const [errorText, setErrorText] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const auth = getAuth();
    const authUserData = () => {
        const {uid, email}  = auth.currentUser;
        return {
            id: uid,
            email,
            token: false,
            provider: auth.currentUser.providerData[0].providerId
        }
    }

    const activateAccData = () => {
        const {client, program} = props.activateInfo;
        return {
            client,
            program
        }
    }

	// telnum formatting
	const telnum = (e) => {
		let val = e.target.value;
		if ((tel.length === 2 && val.length === 3) || (tel.length === 6 && val.length === 7)) {
			val += '-';
		}
		else if ((tel.length === 4 && val.length === 3) || (tel.length === 8 && val.length === 7)) {
			val = val.slice(0, -1);
		}
		setTel(val);
	}

    const newUserData = {
        firstname: firstName,
        lastname: lastName,
        phone: tel,
        deliveryAddress: deliveryAddress,
        favoriteRecipes:[],
        favoriteSkills:[],
        favoriteTips:[],
        notes:{},
        ratings:{},
        timesVisited:0
    }

    const handleSubmit = async () => {
        setErrorText("");
        if (!firstName || !lastName || !tel || !deliveryAddress) { // requires input
			setErrorText("Please fill out all the information!")
		} else {
            setLoading(true);
            if("firstname" in newUserData || "lastname" in newUserData || "phone" in newUserData) {
                let currData = {...props.activateInfo, ...authUserData()};
                console.log('current data', currData);
                // This should be true
                if(currData) { 
                    // If the user has initialized their account with a program code and created a profile already
                    if(!("firstname" in currData)) {
                        console.log('entered 1', ("firstname" in currData), currData);
                        if (currData?.role !== undefined) {
                            newUserData["role"] = "client";
                            newUserData["client"] = ""
                            newUserData["program"] = []
                        } else {
                            newUserData["role"] = "user";
                            newUserData["client"] = currData.client;
                        }
                        let userData = Object.assign({}, currData, newUserData);
                        console.log('userData', userData);
                        
                        if('codeID' in userData) {
                            //delete the code just used to create the account
                            console.log('entered')
                            await deleteDoc(doc(database, 'codes', userData['codeID'])).then(async () => {
                                delete userData['codeID'];
                                 //If the user just created their profile in makeProfile
                                if ("program" in userData && "id" in currData) {
                                    let docRef_1 = doc(database, "users", currData.id);
                                    await setDoc(docRef_1, userData);

                                    if(userData["role"] === 'user') {
                                        let docRef = doc(database, "programs", userData["program"]);
                                        await updateDoc(docRef, { programUsers: arrayUnion(currData['id']) });
                                    }
                                    setLoading(false);
                                    props.login(auth.currentUser.accessToken);
                                    props.setClearActivateAccountInfo();
                                    navigate('/');
                                }       
                            })

                        } else {
                                    let docRef_2 = doc(database, "users", currData.id);
                                    await setDoc(docRef_2, userData);
                                    props.login(auth.currentUser.accessToken);
                                    props.setClearActivateAccountInfo();
                                    navigate('/');
                                }
                    } 
                }
            }
        }    

    }

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" className={props.formLabel}>
                    First Name
                </Typography>

                <TextField 
                    fullWidth
                    value={firstName}
                    id="firstname"
                    placeholder="Enter First Name"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{style: {fontSize: 14}}}
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyPress={(e) => name(e)}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'8px'}}>
                <Typography variant="body2" className={props.formLabel}>
                    Last Name
                </Typography>

                <TextField 
                    fullWidth
                    value={lastName}
                    id="lastname"
                    placeholder="Enter Last Name"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{style: {fontSize: 14}}}
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyPress={(e) => name(e)}
                    required
                />
            </Grid> 

            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'8px'}}>
                <Typography variant="body2" className={props.formLabel}>
                    Phone Number
                </Typography>

                <TextField 
                    fullWidth
                    value={tel}
                    id="phone-number"
                    placeholder="Enter Phone Number"
                    type="tel"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{style: {fontSize: 14}}}
                    onChange={(e) => telnum(e)}
                    onKeyPress={(e) => phone(e)}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'8px'}}>
                <Typography variant="body2" className={props.formLabel}>
                    Delivery Address
                </Typography>

                <TextField 
                    fullWidth
                    id="delivery-address"
                    value={deliveryAddress}
                    placeholder="Enter Delivery Address"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{style: {fontSize: 14}}}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                    required
                    onKeyPress={(e) => {
                        //consoleToLog(`Pressed keyCode ${ev.key}`);
                        if (e.key === 'Enter') {
                            handleSubmit();
                          e.preventDefault();
                        }
                      }}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'8px'}}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    className={props.submitButton}
                >
                    {loading ? <CircularProgress size={20} thickness={4.0} style={{marginRight:'8px', color:'white'}}/> : 
                    <ExitToAppIcon style={{marginRight:'8px'}}/>
                    }  SUBMIT
                </Button>    
            </Grid>

            {(errorText !== "") && 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="subtitle1" 
                        className={props.errorMsg}>
                    {errorText}
                    </Typography>
                </Grid>
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    activateInfo: state.activateAcc.activateInfo
})

const mapDispatchToProps = (dispatch) => ({
    login: (token) => dispatch(login(token)),
    setUser: (user) => dispatch(setUser(user)),
    setClearActivateAccountInfo: () => dispatch(setClearActivateAccountInfo()),
    setAllRecipes: (recipes) => dispatch(setAllRecipes(recipes)),
    setAllPrograms: (programs) => dispatch(setAllPrograms(programs)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading))

  });

export default connect(mapStateToProps, mapDispatchToProps)(MakeProfile);