import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import CardComponent from './CardComponent';
import RecipeNotes from './RecipeNotes';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { database } from '../firebase/firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { setUser } from '../actions/authUser';
import {inFav} from '../util/appUtil';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    heading : {
        fontSize:'30px',
        fontWeight:'600',
        marginBottom: '16px'
    },
    loading: {
        position:'absolute',
        left:'48%',
        right:'50%',
        top:'20%'
    },
    programHeading : {
        fontSize:'22px',
        fontWeight:'600',
        color:'#585858',
        [theme.breakpoints.down('md')] : {
            fontSize:'20px', 
        },
        [theme.breakpoints.down('sm')] : {
            fontSize:'18px', 
        },
    },
    designLine : {
        height: '5px',
        borderTop: '1px solid #009f4f',
        borderBottom: '1px solid #009f4f',
        width:'100%', 
        margin: '2px auto 0 auto',
        [theme.breakpoints.down('md')] : {
            width:'68%', 
        },
        [theme.breakpoints.down('sm')] : {
            width:'55%', 
            margin: '0 8px',
        },
        [theme.breakpoints.down('xs')] : {
            width:'40%', 
            margin: '0 8px',
        }
    },
    headingIcon: {
        marginLeft:'8px',
        [theme.breakpoints.down('md')] : {
            width:'80%', 
        },
    }
}))

const RecipesClient = (props) => {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [recipeObj, setRecipeObj] = useState(undefined);
    //const [recipesUser, setRecipesUser] = useState([]);
    const [masterRecipesUser, setMasterRecipeUser] = useState([]);
    const isMount = useIsMount();

    const user = props.loggedInUser;
    const clientPrograms = props.loggedInUser?.program;
    useEffect(() => {
        if(isMount) {
            let recipesUser = [];
            if (clientPrograms && clientPrograms.length > 0) {
            clientPrograms.forEach(async (program) => {
                if (program !== "") {
                    const endDate = props.programs[program]?.programEndDate;
                    if(endDate && endDate <= Date.now()) {
                            const docRef = doc(database, "users", user.id);
                            const docSnap = await getDoc(docRef);
                            let prevPrograms = docSnap.get('prevPrograms');
                            if (!prevPrograms || prevPrograms && prevPrograms.length === 0) {
                                prevPrograms = [program]
                            } else if(!prevPrograms.includes(user.program)) {
                                prevPrograms.push(program)
                            }
                            await updateDoc(docRef, {
                                prevPrograms: prevPrograms, program: [] 
                            });

                            const new_obj = { ...props.loggedInUser, prevPrograms: prevPrograms, program: [] }
                            props.setUser(new_obj);
                        }
                    }
                    
                    const keysList = props.programs[program]?.programRecipes ? Object.keys(props.programs[program]?.programRecipes) : [];
                    if (user?.role === "client") { 
                        if (program !== "") {
                        if (
                            props.programs[user.program]?.programRecipes !== null ||
                            props.programs[user.program]?.programRecipes !== []
                        ) {
                            var i;
                            for (i = 0; i < keysList.length; i++) {
                                var d = Date.parse(
                                    props.programs[program].programRecipes[keysList[i]] +
                                    "T00:00:00.0000"
                                );
                                if (d < Date.now()) {
                                    recipesUser.push(props.recipes[keysList[i]]);
                                }
                            }
                            setMasterRecipeUser(oldArray => [...oldArray, recipesUser])
                            }
                        }
                    }
                })
            }
        }
    }, [masterRecipesUser]);

    console.log('recipes user', masterRecipesUser);


    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    return (
        <div className={classes.mainContent}>
            <Grid item md={12} style={{marginTop:'54px'}}> 
               <Typography className={classes.heading}> 
                   Recipes
                </Typography>   

                {props.isLoading ? 
                    <CircularProgress className={classes.loading} color='primary' />
                    :
                    <>
                {masterRecipesUser.length > 0 ?  masterRecipesUser.map((recipesUser,index) => {
                   return <Grid item container direction='column' key={index}>
                       {recipesUser && recipesUser.length > 0 &&
                        <> {/**display current program recipes if any */}
                            <Grid item style={{display:'flex', alignItems:'center'}}>
                                <Grid item>
                                    <Typography className={classes.programHeading}>
                                        {props.programs[clientPrograms[index]]?.programName}
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} className={classes.designLine}> 
                                </Grid>

                                <Grid item style={{marginLeft:'auto'}}>
                                    <img src='/images/heading-icon.svg' className={classes.headingIcon}/>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={6} style={{marginTop:'1px'}}>
                                {recipesUser && recipesUser.length > 0 ? 
                                recipesUser.map((recipe, index) => {
                                    let date_upload = props.programs[clientPrograms[index]]?.programRecipes[recipe.id];
                                    return <Grid item xs={12} sm={12} md={6} lg={6} key={recipe.id}>
                                                <CardComponent 
                                                    title={recipe.nameOfDish}
                                                    imageSrc={recipe.images}
                                                    info={recipe.description}
                                                    averageRatings={recipe.avgRating}
                                                    recipeRating={recipe.numRatings}
                                                    recipeDateUploaded={moment(date_upload).format('DD MMM YYYY')}
                                                    handleDrawerOpen={handleDrawerOpen}
                                                    facts={recipe.recipeFact}
                                                    ingredients={recipe.descriptionIngredients}
                                                    recipeObj={recipe}
                                                    setRecipeObj={setRecipeObj}
                                                    notesList={props.loggedInUser.notes}
                                                    isInFavList={inFav(recipe.id, user?.favoriteRecipes)}
                                                    isFromFavoritePage={false}
                                                    userRating={
                                                        recipe.id in user?.ratings ? user?.ratings[recipe.id] : 0
                                                    }
                                                />
                                            </Grid>
                                    }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <h1>
                                                No Recipes Found
                                            </h1>
                                        </Grid>   
                                    }
                            </Grid>
                        </>
                        }
                    </Grid>   
                    }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                            <h1>
                                No Recipes Found
                            </h1>
                        </Grid> 
                    }           
                    <Grid item>
                        {
                        user.prevPrograms && user.prevPrograms.length > 0 && 
                        user.prevPrograms.map((program, index) => {
                            let prevProgramName = props.programs[program]?.programName;
                            let recipeIDs = Object?.keys(props.programs[program]?.programRecipes);
                    return <Grid item xs={12} sm={12} style={{margin:'48px auto 0px auto'}} key={program.program}>
                                <Grid item style={{display:'flex', alignItems:'center', margin:'0px 0px 8px 0px'}}>
                                    <Grid item>
                                        <Typography className={classes.programHeading}>
                                            {prevProgramName}
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={9}  className={classes.designLine}
                                        style={{ margin: '0px auto'}}> 
                                    </Grid>

                                    <Grid item style={{marginLeft:'auto'}}>
                                        <img src='/images/heading-icon.svg' className={classes.headingIcon}/>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={6} style={{marginTop:'8px'}}>

                                    {
                                        recipeIDs && recipeIDs.length > 0 ? 
                                        recipeIDs.map((id) => {
                                            let recipe = props.recipes[id];
                                            let date_upload = props.programs[clientPrograms[index]]?.programRecipes[recipe.id];
                                            return <Grid item xs={12} sm={12} md={6} lg={6} key={recipe.id}>
                                                        <CardComponent 
                                                            title={recipe.nameOfDish}
                                                            imageSrc={recipe.images}
                                                            info={recipe.description}
                                                            averageRatings={recipe.avgRating}
                                                            recipeRating={recipe.numRatings}
                                                            recipeDateUploaded={moment(date_upload).format('DD MMMM YYYY')}
                                                            handleDrawerOpen={handleDrawerOpen}
                                                            facts={recipe.recipeFact}
                                                            ingredients={recipe.descriptionIngredients}
                                                            recipeObj={recipe}
                                                            setRecipeObj={setRecipeObj}
                                                            notesList={props.loggedInUser.notes}
                                                            isInFavList={inFav(recipe.id, user?.favoriteRecipes)}
                                                            isFromFavoritePage={false}
                                                        />
                                                    </Grid>
                                        }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <h1>
                                                    No Recipes Found
                                                </h1>
                                            </Grid>   
                                    }
                                    </Grid>
                                </Grid>
                            })
                        }

                    </Grid>
                    {  props.loggedInUser?.notes &&
                    <RecipeNotes openDrawer={openDrawer}
                        handleDrawerClose={handleDrawerClose}
                        recipeObj={recipeObj}/>  }   
                </>
                    
                    }
            </Grid>

        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    loggedInUser: state.auth.loggedInUser,
    recipes: state.programsRecipes.recipes,
    isLoading: state.loading.isLoading,
    programs: state.programsRecipes.programs
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecipesClient);