//manage home page loading
const defaultState = {
    isLoading: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.isLoading
            };
            
        default:
            return state;
    }
};