import React, {useState} from 'react';
import { Button, Grid, Typography, TextField, CircularProgress} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { database } from '../firebase/firebase';
import { getDoc, doc } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';
import {connect} from 'react-redux';
import { setActivateAccountInfo } from '../actions/activateAcc';

const ActivateAccountPage = (props) => {
    const [code, setCode] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const onHandleActivationCode = async () => {
        if(code !== '') {
            setLoading(true);
            const docRef = doc(database, "codes", code.trim().toUpperCase());
            let docSnap = await getDoc(docRef);
            setLoading(false);
            if(docSnap.exists() && code === docSnap.id)  {
                console.log('new data', docSnap.data());
                let data = docSnap.data();
                console.log('newly acquired data', data);
                props.setActivateAccountInfo(data);
                navigate("/profile/create", {state: data});
                props.setActivateAccount(false);
                return Promise.resolve(data);
                
            } else {
                 Promise.reject("Code is incorrect");
                 setErrorText("Code is incorrect");
            }

        }

    }

    return (
        <>
            <Grid item md={12} lg={12}>
                <Typography variant="body2" className={props.formLabel}>
                    Activation code
                </Typography>

                <TextField 
                    fullWidth
                    id="activation-code"
                    placeholder="Enter activation code"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{style: {fontSize: 14}}}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    onKeyPress={(e) => {
                        //consoleToLog(`Pressed keyCode ${ev.key}`);
                        if (e.key === 'Enter') {
                          onHandleActivationCode();
                          e.preventDefault();
                        }
                      }}
                />
            </Grid>

            <Grid item md={12} lg={12} style={{marginTop:'12px'}}>
                <Button
                    variant="contained"
                    color="primary"
                    className={props.submitButton}
                    onClick={onHandleActivationCode}
                >
                    {loading ? <CircularProgress size={20} thickness={4.0} style={{marginRight:'8px', color:'white'}}/> :
                    <ExitToAppIcon style={{marginRight:'8px'}}/>
                    } SUBMIT
                </Button>
            </Grid>

            {(errorText !== '') && 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="subtitle1" 
                    className={props.errorMsg}>
                    {errorText}
                    </Typography>
                </Grid>
            }
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setActivateAccountInfo: (info) => dispatch(setActivateAccountInfo(info))
})

export default connect(null, mapDispatchToProps)(ActivateAccountPage);