import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Container} from '@material-ui/core';
import Header from '../components/Header';
import Recipes from '../components/Recipes';
import Home from '../components/Home';
import Favorites from '../components/Favorites';
import { useLocation } from 'react-router-dom';
import MakeThisRecipe from '../components/MakeThisRecipe';
import { selectedState, setSelectedState } from '../actions/programsRecipes';
import { useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import Profile from './Profile';
import RecipesClient from '../components/RecipesClient';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc, getDocs, collection } from "firebase/firestore"; 
import { database } from '../firebase/firebase';
import {setAllRecipes, setAllPrograms} from '../actions/programsRecipes';
import {setLoading} from '../actions/loading';
import {setUser} from '../actions/authUser';
import NoHomePage from '../components/NoHomePage';

const useStyles = makeStyles((theme) => ({
    mainBg: {
        background: theme.palette.primary.light,
        height: "100vh",
        flexGrow: 1,
        flexShrink: 1
     }
}))

const DashboardPage = (props) => {
    const classes = useStyles();
    const location = useLocation();

    let path = location?.pathname;

    const {slug} = useParams();

    useEffect(() => {
        document.title = "EatWell";
    }, []);

    useEffect(() => {
        const auth = getAuth();
        let authstate = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const docRef = doc(database, "users", user.uid);
                props.setLoading(true);
                const docSnap = await getDoc(docRef);
                if(docSnap.exists()) {
                  props.setUser(docSnap.data());
                  console.log('User Logged In');
    
                  //set All Recipes
                  const querySnapshot = await getDocs(collection(database, "recipes"));
                  var recipes = {};
                  querySnapshot.forEach((doc) => {
                      recipes[doc.id] = {...doc.data()}
                  });
                  props.setAllRecipes(recipes);
                  console.log('get All recipes data', recipes);
    
                  //set All Programs
                  const querySnapshot1 = await getDocs(collection(database, 'programs')).catch((err) => console.log(err));
                  //console.log('****querySnapshot1', programs);
                  var programs = {};
                  querySnapshot1?.forEach((doc) => {
                      programs[doc.id] = {...doc.data()}
                  });
                  props.setAllPrograms(programs);
                  console.log('program data', programs);
    
                } else {
                  console.log("User Logged Out",);
                }
    
                props.setLoading(false);
                } 
          });
    
          return () => {
                authstate();
          };
      }, []);

    useEffect(() => {
        if(path === '/') {
            props.setSelectedState(selectedState.SHOW_HOME_PAGE);
        } else if(path === '/recipes') {
            props.setSelectedState(selectedState.SHOW_RECIPES_PAGE);
        } else if(path === '/favorites') {
            props.setSelectedState(selectedState.SHOW_FAVORITES_PAGE);
        } else if(path === '/profile') {
            props.setSelectedState(selectedState.SHOW_PROFILE_PAGE);
        }
    }, [location?.pathname])

    const showSelectedStateComponent = () => {
        if(props.navBarState === selectedState.SHOW_HOME_PAGE && location?.pathname === '/') {
            if(props.loggedInUser?.role === 'user') {
                return  <Home />
            } else {
                return  <NoHomePage />
            }
        } else if(props.navBarState === selectedState.SHOW_RECIPES_PAGE && location?.pathname === '/recipes') {
            if(props.loggedInUser?.role === 'client') {
                return <RecipesClient />
            } else {
                return <Recipes />
            }
        } else if(props.navBarState === selectedState.SHOW_FAVORITES_PAGE && location?.pathname === '/favorites') {
            return <Favorites />
        } else if(props.navBarState === selectedState.SHOW_RECIPES_PAGE && slug) {
            return <MakeThisRecipe />
        } else if(props.navBarState === selectedState.SHOW_PROFILE_PAGE) {
            return <Profile />
        }
    } 

    return (
        <div className={classes.mainBg}>
            <Grid item container direction="column">
                <Header />
                <Grid item>
                    { showSelectedStateComponent() }
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    navBarState: state.programsRecipes.navBarState,
    loggedInUser: state.auth.loggedInUser,
    accessToken: state.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedState : (value) => dispatch(setSelectedState(value)),
    setUser: (user) => dispatch(setUser(user)),
    setAllRecipes: (recipes) => dispatch(setAllRecipes(recipes)),
    setAllPrograms: (programs) => dispatch(setAllPrograms(programs)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);