export const inFav = (recipe_id, favRecipesArr) => {
    for (let i = 0; i < favRecipesArr?.length; i++) {
        if (recipe_id === favRecipesArr[i]) {
            return true;
        }
    }
    return false;
}

 // name validation check, only able to use letters
 export const name = (e) => {
    const re = /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// phone validation check, key pressed when entering telephone number can only be numbers
export const phone = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key) || e.target.value.length > 11) {
        e?.preventDefault();
    }
}

// telnum formatting
export const telnum = (e, tel, setTel) => {
    let val = e.target.value;
    if ((tel.length === 2 && val.length === 3) || (tel.length === 6 && val.length === 7)) {
        val += '-';
    }
    else if ((tel.length === 4 && val.length === 3) || (tel.length === 8 && val.length === 7)) {
        val = val.slice(0, -1);
    }
    setTel(val);
}

export const getDateString = timestamp => {
    let date = new Date(timestamp);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    let str = date.getFullYear() + "-" + month + "-" + day;
    return str;
  }


export const calculateWeekEndDate = (datestring) => {
    let date = new Date();
    let args = datestring.split("-");
    date.setFullYear(parseInt(args[0]));
    date.setMonth(parseInt(args[1]) - 1);
    date.setDate(parseInt(args[2]) + 7 - 1);
    return date.getTime();
  }

export const calculateWeekStartDate = (datestring) => {
  let date = new Date();
  let args = datestring.split("-");
  date.setFullYear(parseInt(args[0]));
  date.setMonth(parseInt(args[1]) - 1);
  date.setDate(parseInt(args[2]) + 7);
  return date.getTime();
}  

export const getWeeksArray = (start_date, numberOfWeeks) => {
    let weekDateArr = [];
    let arr = [];
    const weeks = numberOfWeeks;
        for(let i = 0; i < weeks; i++) {
          arr.push((i + 1));
        }


    let weekStartDate = getDateString(start_date);
    for(let i = 0; i < arr.length; i++) { //get week no and weekstartdate and weekendate in array
        let weekDates = {
        week:  i + 1,
        weekStartDate: weekStartDate,
        weekEndDate: getDateString(calculateWeekEndDate(weekStartDate))
        }
        weekStartDate = getDateString(calculateWeekStartDate(weekStartDate))

        weekDateArr.push(weekDates);
    }

    return weekDateArr
}

export const getUpdatedWeekArray = (weeklyRecipes, weekDateArr) => {

    let result = weeklyRecipes.reduce((results, item) => {
        results[item.week] = results[item.week] || [];
        results[item.week].push(item.recipes);
    
        return results;
    }, {});

    weeklyRecipes = Object.keys(result).map(function (key) {
        return {week: key, recipes: result[key]};
    });

    return weeklyRecipes.map((item) => {
        weekDateArr.map(({week, weekStartDate, weekEndDate}) => {
            if(week === Number(item.week)) {
                   item.weekStartDate =  weekStartDate;
                   item.weekEndDate = weekEndDate;
            }
        });
        return  item
    }); 

}