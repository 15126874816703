import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { loadState, saveState } from './localStorage'

const persistedState = loadState();
const store = configureStore(persistedState);
//Things you want to be persisted all time
store.subscribe(() => {
  saveState({
      auth: store.getState().auth,
      activateAcc: store.getState().activateAcc,
      programsRecipes: store.getState().programsRecipes
  });
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
