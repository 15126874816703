import {selectedState} from '../actions/programsRecipes';

const defaultState = {
    programs: [],
    recipes: [],
    navBarState: selectedState.SHOW_HOME_PAGE
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_ALL_PROGRAMS':
            return {
                ...state,
                programs: action.programs
            }

        case 'SET_ALL_RECIPES':
            return {
                ...state,
                recipes: action.recipes
            } 

        case 'SET_SELECTED_STATE':
            return {
                ...state,
                navBarState: action.value
            }    

         case 'CLEAR_PROGRAM_AND_RECIPES':
             return defaultState

            default:
                return state;  
        }   
        
       

}