import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import CardComponent from './CardComponent';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {inFav} from '../util/appUtil';
import RecipeNotes from './RecipeNotes';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase/firebase';
import { useIsMount } from './useIsMount';
import ReactPlayer from "react-player";
import { selectedState, setSelectedState } from '../actions/programsRecipes';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        margin:'0px auto',
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    playerWrapper: {
        position:'relative',
        marginTop:'0',
        zIndex:'0 !important',
        [theme.breakpoints.down(900)]: {
            marginTop:'8px',
            marginBottom:'8px'
        }, 
        '& .react-player' : {
            position:'sticky', 
            top:0, 
            left:0,
            height:'100%',
            [theme.breakpoints.down(900)]: {
                marginTop:'8px',
                marginBottom:'8px'
            }, 
            [theme.breakpoints.down("sm")]: {
                marginTop:'8px',
                marginBottom:'8px'
            },   
        },
    },
    video : {
        // position: 'absolute',
        // top: 0,
        // left: 0 // Percentage ratio for 16:9
         [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginTop:'0',
            height:(width) => {
                return width*0.4
            },
        },   
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            margin:'0px 0px 0px 0px',
        }, 
    },
    imageList : {
      position:'relative',
      '& .pdfImage': {
        width:'100%',
        height:(width) => {
            return width*0.5;
        },
        [theme.breakpoints.down("sm")]: {
            marginTop:'16px',
            width:'100%',
            height:(width) => {
                return width*1.2;
            }
        },
      
        }
    },
    makeRecipeButton: {
        width:'200px',
        borderRadius:'50px', 
        marginTop:'8px',
    },
    pdfDownloadButton: {
        width:'100%',
        backgroundColor:'#000000bf',
    },
    backIcon : {
        cursor:'pointer',
        marginLeft:'-40px',
        [theme.breakpoints.down("sm")]: {
            marginLeft:'-28px !important',
            marginBottom:'8px'
        },
        [theme.breakpoints.down("md")]: {
            marginRight:'32px'
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft:'9% !important'
        },
    },
    cardDesign :{
        margin:'0px 0px 0px -100px',
        [theme.breakpoints.down("md")]: {
            flexBasis:'82%',
            marginLeft:'-80px'
        },
        [theme.breakpoints.down("sm")]: {
            flexBasis:'79.4%',
            marginLeft:'-28px'
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft:'0'
        },
    },
    survey: {
		marginTop: "5vh",
		marginBottom: "1vh",
	},
    loading: {
        position:'absolute',
        left:'50%',
        right:'50%',
        top:'5%'
    },
    vpCenter : {
        display:'block !important',
    },
    videoImageContainer : {
        marginTop:"24px",
    },
    buttonActions : {
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        padding:'32px 0px',
        [theme.breakpoints.down("sm")]: {
            position:'relative',
        },
    }
}));

function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		// only execute all the code below in client side
		if (typeof window !== "undefined") {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}

			// Add event listener
			window.addEventListener("resize", handleResize);

			// Call handler right away so state gets updated with initial window size
			handleResize();

			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}

const MakeThisRecipe = (props) => {
    const { width } = useWindowSize();
    const { height } = useWindowSize();
    const classes = useStyles(width, height);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [showSurvey, setShowSurvey] = useState(false);
    const [loading, setLoading] = useState(false);

    const user = props.loggedInUser;
    const location = useLocation();
    const recipeObj = location.state?.recipeObj;

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const isMount = useIsMount();

    const fromHomePage = location.state?.fromHomePage
    console.log('12345', location);

    useEffect(async () => {
        if(isMount) {
            //const storage = getStorage();
            if (recipeObj && recipeObj.recipeImgs.length > 0) {
                for (let i = 0; i < recipeObj?.recipeImgs?.length > 0; i++) {

                    const vals = Object.values(recipeObj?.recipeImgs);
                    setLoading(true);
                    const imagesRef = ref(storage, recipeObj.id + i + ".pdf");
                        await getDownloadURL(imagesRef)
                        .then((url) => {
                            // Insert url into an <img> tag to "download"
                            //console.log('image fetch success', url);
                            if (vals.includes(recipeObj.id + i + ".pdf")) {
                                setImageList((imageList) => [...imageList, url]);
                                setLoading(false);
                            } else {
                                setImageList((imageList) => [...imageList, ""]);
                                setLoading(false);
                            }
                        })
                        .catch((error) => {
                            console.log('image fetch error', error);
                            setLoading(false);
                        });
                    }  
            }  
        }      

    }, []);

    const onBackIconClick = () => {
        if(fromHomePage) {
            navigate('/');
        } else {
            navigate('/recipes');
        }
    }

    const google_form = recipeObj?.surveyURL;

    return (
        <div className={classes.mainContent}>
            {showSurvey ?
            <Grid item style={{margin:'64px 0px'}}>
                <Grid item container justifyContent='center'>
                    <Grid item 
                        style={{cursor:'pointer'}}
                        onClick={() => {
                            setShowSurvey(false);
                            if(props.fromHomepage) {
                                props.setSelectedState(selectedState.SHOW_RECIPES_PAGE);
                            }
                            navigate(`/recipes/${recipeObj?.id}`, {state: {recipeObj, fromHomePage: props.fromHomepage}})
                        }}>
                        <ArrowBackIcon style={{marginTop:'8px', marginLeft:'64px !important'}}/>
                    </Grid>
                    <Grid item xs={11} md={6} >
                        {google_form.includes("docs.google.com") ? 
                        <embed src={recipeObj?.surveyURL} width="100%" height={height}>
                        
                        </embed>
                        :
                        <Typography style={{fontSize:'16px', fontWeight:'600', marginLeft:'50px', marginTop:'8px'}}>
                            Sorry! Review form is not available
                        </Typography>
                        }
                        {/* <iframe src={recipeObj?.surveyURL} width="100%" height="100vh" 
                        frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                    </Grid>
                </Grid>
            </Grid>
            :
            <Grid item sm={12} style={{margin:'60px 0px 32px 0px', height:'100%'}}>
                <Grid item container justifyContent='center'>
                    {console.log('12345', props.fromHomepage)}
                    <Grid item xs={12} sm={9} md={1} 
                        onClick={onBackIconClick}>
                        <ArrowBackIcon className={classes.backIcon}/>
                    </Grid> 

                    <Grid item xs={12} sm={10} md={10} lg={11} className={classes.cardDesign}>
                        <CardComponent 
                            fromMakeThisRecipe={true}
                            title={recipeObj.nameOfDish}
                            imageSrc={recipeObj.images}
                            info={recipeObj.description}
                            averageRatings={recipeObj.avgRating}
                            recipeRating={recipeObj.numRatings}
                            recipeDateUploaded={moment(recipeObj.dateUploaded).format('DD MMMM YYYY')}
                            recipeObj={recipeObj}
                            notesList={props.loggedInUser.notes}
                            isInFavList={inFav(recipeObj.id, user?.favoriteRecipes)}
                            handleDrawerOpen={handleDrawerOpen}
                            setShowSurvey={setShowSurvey}
                            userRating={
                                recipeObj.id in user?.ratings ? user?.ratings[recipeObj.id] : 0
                            }
                        />
                    </Grid>
                </Grid>

                <Grid item container justifyContent='center' spacing={1}  className={classes.videoImageContainer}>
                    <Grid item xs={12} sm={12} md={6} className={classes.playerWrapper} >
                        {/* <iframe src={"https://player.vimeo.com/video/"+ recipeObj.videoRecipe}relative 
                        frameBorder="0" allow="autoplay; fullscreen"></iframe> */}
                        {recipeObj.videoRecipe !== '' &&
                        <ReactPlayer  url={"https://vimeo.com/"+`${recipeObj.videoRecipe}`} controls={true}
                            className="react-player"
                            width="98%"
                            // height={width*0.4}
                        />}
                    </Grid>

                    <Grid item xs={12} sm={12} md={5} className={classes.imageList}>
                    
                        { loading ? <CircularProgress size={20} className={classes.loading}/>
                            :   
                            imageList && imageList.length > 0 &&
                            imageList.map((url) => {
                                if (url !== '') {
                                    // return <img  className='pdfImage' src={url} alt={recipeObj.nameOfDish} />
                                    return (
                                        <div>
                                            <object className='pdfImage' data={url} type="application/pdf"></object>
                                            <Button as="a" color="primary" variant="contained" 
                                                href={url}
                                                target="_blank"
                                                className={classes.pdfDownloadButton}>
                                                View recipe booklet in full size
                                            </Button>
                                        </div>
                                    )
                                }
                            })
                        }
                    </Grid>
                    
                </Grid>        

                <div item xs={12} className={classes.buttonActions}>
                    <Typography style={{fontSize:'16px', fontWeight:'600', marginRight:'8px', marginTop:'8px'}}>
                        Did you make this recipe?
                    </Typography>

                    <Button color="primary" variant="contained" 
                        onClick={() => setShowSurvey(true)}
                        className={classes.makeRecipeButton}>
                        Tell us what you think!
                    </Button>
                </div>    

            </Grid>}

            <RecipeNotes openDrawer={openDrawer}
            handleDrawerClose={handleDrawerClose}
            recipeObj={recipeObj}/> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.auth.loggedInUser,
});

export default connect(mapStateToProps)(MakeThisRecipe);