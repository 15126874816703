import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, IconButton, Badge, Tooltip } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ClearIcon from "@material-ui/icons/Clear";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { database, storage } from '../firebase/firebase';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import {getAuth} from 'firebase/auth';
import {connect} from 'react-redux';
import { setUser } from '../actions/authUser';
import {useSnackbar} from 'notistack';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import { selectedState, setSelectedState } from '../actions/programsRecipes';
import ReactCardFlip from "react-card-flip";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow:1,
        borderRadius:'0',
        position:'relative',
        // height:'480px',
        '& .middle' : {
            transition: '.5s ease',
            opacity: '1 !important',
            position: 'absolute',
            top: '45%',
            left: '48%',
            transform: 'translate(-50%, -50%)',
            textAlign:'center',
            width:"100px", 
            height:'100px', 
            background:'red', 
            borderRadius:'50%',
            lineHeight:'148px',
            '& .lockIcon' : {
            fontSize:'60px'
            }
        },
        [theme.breakpoints.down(1300)]: {
            // height:'520px',
        },  
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height:'auto',
            '& .middle' : {
                textAlign:'center',
                width:"90px", 
                height:'90px', 
                background:'red', 
                borderRadius:'50%',
                lineHeight:'130px',
                '& .lockIcon' : {
                fontSize:'20px'
                }
            }  
          },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height:'auto',
            '& .middle' : {
                textAlign:'center',
                width:"80px", 
                height:'80px', 
                background:'red', 
                borderRadius:'50%',
                lineHeight:'130px',
                '& .lockIcon' : {
                fontSize:'15px'
                }
            }    
        },  

    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    media: {
      height: '58%',
      position:'relative',
      [theme.breakpoints.down(1500)]: {
        height: '57%',
        }, 
    [theme.breakpoints.down('sm')]: {
        height: '80%',
        paddingTop: "50.25%",

    },  
    },
    cardActions : {
        width:'190px',
        borderRadius:'50px',
        [theme.breakpoints.down("xs")]: {
            '& .MuiButton-label' : {
                width:'160px',
                fontSize:'10px'
            }
          }
    },
    title : {
        fontSize:'1.4rem',
        fontWeight:'bold',
        [theme.breakpoints.down("xs")]: {
            marginBottom:'8px'
          }
    },
    ratings : {
        margin:'14px 0px -4px -2px'
    },
    timeIcon : {
        color:"#ccc", 
        fontSize:'18px',
        marginRight:'4px'
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        right:'16px'
     },
     overlayIcons : {
        width:'35px',
        height: '35px',
        borderRadius: '50%',
        margin:'0px 4px',
        background: '#fff',
        color: 'light-grey',
        '&:hover' : {
            background:'#fff',
            color:'light-grey'
        }
     },
     icon : {
        fontSize:'18px'
     },
     ratingText : {
        display:'inline',
        marginLeft:'6px', 
        lineHeight:'16px', 
        verticalAlign:'text-top'
     },
     detailsTitle : {
        fontWeight:'600',
        fontSize:'18px'
     },
     detailsImg : {
         width:'250px',
         height:'380px',
         [theme.breakpoints.down(1500)]: {
            width:'210px',
            height:'370px',
          },
          [theme.breakpoints.down(1300)]: {
            width:'190px',
            height:'420px',
          },
          [theme.breakpoints.down(1200)]: {
            width:'140px',
            height:'360px',
          },

        [theme.breakpoints.down("sm")]: {
            width:'100%',
            height:'auto',
            marginBottom:'8px'
          }
     },
     detailsText : {
        fontSize:'1rem',
        fontWeight:'600',
        marginLeft:'-24px',
        overflowX:'hidden',
        overflowY:'auto',
        height:'430px',
        paddingRight:'8px',
        '&::-webkit-scrollbar' : {
           width: '7px !important'
      },
       '&::-webkit-scrollbar-track' : {
           background: theme.palette.primary.light,
           width: '60px !important',
           marginLeft:'8px'
     },
       '&::-webkit-scrollbar-thumb' : {
           border: '8px solid transparent',
           background: '#CFCFCF',
           borderRadius: '10px',
           backgroundClip: 'paddingBox',
     },
     [theme.breakpoints.down(1500)]: {
        height:'410px',
      },
      [theme.breakpoints.down(1300)]: {
        height:'400px',
      },
      [theme.breakpoints.down("md")]: {
        fontSize:'1rem',
        fontWeight:'600',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize:'1.1rem',
        fontWeight:'600',
        height:'auto',
        overflow:'hidden',
        margin:'8px 0px 16px 0px',
      },
    },
     makeRecipeCardContainer: {
        display: 'flex',
        padding: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        // height:'190px',
        position:'relative',
        [theme.breakpoints.down("sm")]: {
            height:'auto !important',
        },
        [theme.breakpoints.down("xs")]: {
            display:'flex',
            padding:'0px',
            flexDirection:'column',
            height:'auto !important',
        }
     },
     makeRecipeContent : {
         paddingTop:'0px',
         paddingLeft:'4px',
         marginLeft:'8px',
        [theme.breakpoints.down("xs")]: {
            marginLeft:'0px',
        }
     },
     makeRecipeTitle: {
        fontSize:'1.5rem',
        fontWeight:'bold',
        marginTop:'-3px',
        [theme.breakpoints.down("sm")]: {
            marginTop:'0px',
            fontSize:'1.2rem',
          },
        [theme.breakpoints.down("xs")]: {
            marginTop:'0px',
            fontSize:'1.1rem',
          }
     },
     makeRecipeMedia: {
        width: "80%",
        height: "300px",
        ["@media (min-width:600px)"]: {
            width: "20%",
            minWidth: "20%",
            height: "190px"
        },
        [theme.breakpoints.down("sm")]: {
            width: "38%",
            minWidth: "38%",
            height: "auto"
          },
        [theme.breakpoints.down("xs")]: {
            height: '80%',
            width: "100%",
            paddingTop: "50.25%",
            position:'relative',
            bottom:'8px'
        },  
    },
    makeRecipeIcons : {
        width:'30px',
        height: '30px',
        borderRadius: '50%',
        margin:'-2px 4px 0px 4px',
        color: 'light-grey',
        border: '1px solid #ccc',
        '&:hover' : {
            color:'light-grey'
        },
        [theme.breakpoints.down("xs")]: {
            width:'30px',
            height: '30px',
        }, 
    },
    makeRecipeButton: {
        width:'200px',
        borderRadius:'50px', 
        marginTop:'8px',
    },
    makeRecipeText: {
        fontSize:'16px', 
        fontWeight:'600',
        [theme.breakpoints.down("sm")]: {
            fontSize:'16px', 
            fontWeight:'600',
          },
    },
    makeRecipeIconContainer : {
        marginLeft:'16px',
        [theme.breakpoints.down("sm")]: {
            marginTop:'8px',
            marginLeft:'0',
        },
    },
    dateIcon : {
        [theme.breakpoints.down(1400)]: {
        },
    },
    ingInfo : {
        fontSize:'13px', 
        [theme.breakpoints.down(1400)]: {
            fontSize:'12.5px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize:'14px',
        },
        
    },
    // imageTextCongtainer : {
    //     overflow:'auto', 
    //     height:'430px'
    // }
    recipeDetailsButton : {
        borderRadius:'50px',
        textAlign:'center',
        margin:'0px auto',
        padding:'1px auto !important',
        marginBottom:'8px',
        [theme.breakpoints.down("sm")]: {
            width:'100%',
            left:'0',
            right:'0',
            transform:'translateX(0px)',
            '& .MuiButton-label' : {
                width:'160px',
                fontSize:'18px'
            }
        },
        [theme.breakpoints.down("xs")]: {
            '& .MuiButton-label' : {
                width:'160px',
                fontSize:'10px'
            }
          }
    },
    titleStyles : {
        marginTop:'8px', 
        whiteSpace: "nowrap", 
        maxWidth: "80ch", 
        overflow: "hidden", 
        textOverflow: "ellipsis"
    },
    ratingsClearIcons : {
        fontSize: "calc(min(5vw, 20px))",
        marginRight:'8px',
        verticalAlign:'text-top',
        marginLeft:'4px',
        marginTop:'-0.5px',
        cursor:'pointer',
        [theme.breakpoints.down("xs")]: {
            marginBottom:'8px'
          }
    },
    makeRecipeActionStyle1 : {
        position:'absolute', 
        top:'58%', 
        left:'21.5%', 
        zIndex:1000,
        [theme.breakpoints.down(1000)]: {
            position:'absolute', 
            top:'55%',
            left:'23%',
        },
        [theme.breakpoints.down("xs")]: {
            position:'relative', 
            top:'-16px', 
            left:'8px',
        }
    },
    makeRecipeActionStyle : {
        zIndex:'9999 !important',
        marginBottom:'10px',
        marginLeft: '37%',
    }
  }));

const CardComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [value, setValue] = useState(2);
    const [showRecipeDetails, setShowRecipeDetails] = useState(false);
    const [nutritionalImgs, setNutritionalImgs] = useState([]);
    const [favRecipes, setFavRecipes] = useState(props.loggedInUser?.favoriteRecipes);
    const [open, setOpen] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [rating, setRating] = useState(props.userRating);

    const [object, setObject] = useState(props.recipeObj);


    useState(() => {
        setObject(props.recipeObj);
    }, [props.recipeObj])

    const noteArr = props.notesList[props.recipeObj?.id];

    const navigate = useNavigate();

    useEffect(async () => {
        //const storage = getStorage();

        //first check if nutritional image not empty
        if (props.recipeObj && props.recipeObj.nutritionalImgs != undefined) {
			for (let i = 0; i < props.recipeObj?.nutritionalImgs?.length; i++) {
                const imagesRef = ref(storage, props.recipeObj.nutritionalImgs[0]);
				await getDownloadURL(imagesRef)
                .then((url) => {
                    // Insert url into an <img> tag to "download"
                    //console.log('image fetch success', url);
                    setNutritionalImgs((nutritionalImgs) => [...nutritionalImgs, url]);
                })
                .catch((error) => {
                    console.log('image fetch error', error)
                });
			}
		}
    }, [props.recipeObj]);

    useEffect(() => {
        setFavRecipes(props.loggedInUser?.favoriteRecipes);
    }, [props.loggedInUser?.favoriteRecipes]);

    const showDetailsClicked = () => {
        setShowRecipeDetails(!showRecipeDetails);
        setIsFlipped(!isFlipped);
    }
    
    //details about recipe
    const renderRecipeDetails = () => {
        return (
            <Card elevation={0} className={classes.root} 
                style={{borderBottom: '5px solid white',
                        backgroundColor: props.fromHomepage && '#FAFAFA'
                        }}
            >
                <Grid item style={{padding:'0 16px'}}>
                    <Grid item container 
                        alignItems="center"
                        justifyContent="space-between">
                        <Typography variant="body1" className={classes.detailsTitle}>
                            {object.title}
                        </Typography>

                        <IconButton 
                            style={{marginRight:'-16px'}}
                            onClick={showDetailsClicked}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>

                    <Grid item container justifyContent='center'>
                        <Grid item xs={12} sm={12} md={6} lg={6} alignContent='center'>
                            {/* {nutritionalImgs && nutritionalImgs.length > 0 &&
                            nutritionalImgs.map((url) => {
                                return <img className={classes.detailsImg} src={url} key={url}/>
                            })
                            } */}
                            <img className={classes.detailsImg} src={nutritionalImgs[0]}/>
                            <Button 
                                className={classes.recipeDetailsButton}
                                onClick={() =>{
                                    props.setSelectedState(selectedState.SHOW_RECIPES_PAGE);
                                    navigate(`/recipes/${props.recipeObj?.id}`, {state: {recipeObj: props.recipeObj, fromHomePage: props.fromHomepage}});
                                    
                                    }}
                                color="primary"
                                variant="contained">
                                Make this Recipe
                            </Button>
                        </Grid>
                        

                        <Grid item xs={12} sm={12} md={6} lg={6}
                            className={classes.detailsText}>
                                <Grid item container justifyContent="flex-start" spacing={1} direction="column">
                                    <Grid item>
                                        <Typography style={{fontWeight:'600', fontSize:'16px'}}>
                                            Recipe Facts:
                                        </Typography>

                                        <Typography variant="subtitle1" className={classes.ingInfo}>
                                            {object.recipeFact}
                                        </Typography>
                                    </Grid>

                                    <Grid item >
                                        <Typography style={{fontWeight:'600', fontSize:'16px'}}>
                                            Ingredients:
                                        </Typography>
                                        <Typography variant="subtitle1"  className={classes.ingInfo}>
                                           {object.descriptionIngredients}
                                        </Typography>
                                    </Grid>

                                    <Grid item >
                                        <Typography style={{fontWeight:'600', fontSize:'16px'}}>
                                            Allergens:
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {object?.recipeAllergens}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        )
    }
  
    const handleClose = () => {
        setOpen(false);
    }
    
    const onFavoriteButtonClick = async () => {

            const recipeRef = doc(database, 'recipes', object.id);

                if(favRecipes && favRecipes.length > 0) {
                    if(!favRecipes.includes(object.id)) {

                        //if favoriteRecipe array is not empty and recipe is not in favorite list                        
                        favRecipes.push(object.id);
                        const docRef = doc(database, 'users', props.loggedInUser?.id);
                        await updateDoc(docRef, {favoriteRecipes: favRecipes});

                        enqueueSnackbar("Recipe Added To Favorites", {
                            variant: 'success'
                        });
                        
                        //update num of favorite recipes
                        const recipeDoc = await getDoc(recipeRef);
                        if(recipeDoc.exists()) {
                            await updateDoc(recipeRef, {numFavorites: recipeDoc.data().numFavorites+ 1});

                        } else {
                            console.log('recipe not found');
                            enqueueSnackbar("Recipe Could Not Be Added Favorites", {
                                variant: 'error'
                            });
                        }

                        //update redux
                        const new_obj = { ...props.loggedInUser, favoriteRecipes: favRecipes}
                        props.setUser(new_obj);
                        setOpen(true);
                    } else {


                        //if its in favorite list remove recipe from favorites
                        const docRef = doc(database, 'users', props.loggedInUser?.id);
                        favRecipes.splice(favRecipes.indexOf(props.recipeObj.id), 1);
                        await updateDoc(docRef, {favoriteRecipes: favRecipes});
                        enqueueSnackbar("Recipe Removed From Favorites", {
                            variant: 'success'
                        });

							//update num of favorite recipes
                            const recipeDoc = await getDoc(recipeRef);
                            if(recipeDoc.exists()) {
                                await updateDoc(recipeRef, {numFavorites: recipeDoc.data().numFavorites - 1});
                            } else {
                                console.log('recipe not found');
                                enqueueSnackbar("Error", {
                                    variant: 'error'
                                });
                            }

                            //update redux
                            const new_obj = { ...props.loggedInUser, favoriteRecipes: favRecipes}
                            props.setUser(new_obj);

                            if(props.isFromFavoritePage) {
                                props.onFavClick();
                            }
                    }

                } else {
                    //first time add recipe to favorite if favoriteRecipeArray is empty
                    const docRef = doc(database, 'users', props.loggedInUser?.id);
                    await updateDoc(docRef, {favoriteRecipes: [object.id]});
                    enqueueSnackbar("Recipe Added To Favorites", {
                        variant: 'success'
                    });
                    
                    //update num of favorite recipes
                    const recipeDoc = await getDoc(recipeRef);
                    if(recipeDoc.exists()) {
                        await updateDoc(recipeRef, {numFavorites: recipeDoc.data().numFavorites+ 1});
                    } else {
                        console.log('recipe not found');
                        enqueueSnackbar("Recipe Could Not Be Added Favorites", {
                            variant: 'error'
                        });
                    }

                    //update redux
                    const new_obj = { ...props.loggedInUser, favoriteRecipes: [props.recipeObj.id]}
                    props.setUser(new_obj);
                
                }
    }

    const uploadRating = async (recipe, newRating, oldRating) => {
        if(newRating === oldRating) {return;}
        let newData = {numRatings: recipe.numRatings, avgRating: recipe.avgRating};
        let sumRatings = recipe.numRatings * recipe.avgRating;
        if(oldRating === 0) {
            sumRatings += newRating;
            newData.numRatings = newData.numRatings + 1;
        } else if(newRating === 0) {
            sumRatings -= oldRating;
            newData.numRatings = newData.numRatings - 1;
        } else {
            sumRatings -= oldRating;
            sumRatings += newRating;
        }
        newData.avgRating = sumRatings / newData.numRatings;
       // console.log('newdata', newData);
        setObject({...recipe, ...newData});
        let recipeRef = doc(database, 'recipes', props.recipeObj.id);
        return updateDoc(recipeRef, newData);
    }

    //console.log('new recipe obj', props.recipeObj)

    const onRatingsClick = async (val) => {
        let ratings = props.loggedInUser?.ratings;
        setRating(val);
        if(props.loggedInUser !== {}) {
            console.log('recipeObj',object.id);
            ratings[object.id] = parseFloat(val);
            console.log('recipeObj',object.id, ratings);
            const docRef = doc(database, 'users', props.loggedInUser.id);
            await updateDoc(docRef, {ratings});

            const new_obj = { ...props.loggedInUser, ratings: ratings}
            props.setUser(new_obj);

        } else {

        }
        uploadRating(object, parseFloat(val), parseFloat(rating))
        .then(() => {
            enqueueSnackbar('Ratings updated successfully', {variant:'success'});
        })
		.catch((err) => {
			console.log(err);
            enqueueSnackbar('Problem updating Ratings', {variant:'error'});
		});
        

    } 

    //recipe design for recipe component
    const renderRecipeCardDesign = () => {
        return (
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <Card elevation={0} className={isFlipped ? `${classes.root} flip` : classes.root}
                style={{ borderBottom : '5px solid #009f4f',
                         backgroundColor: props.comingWeeks ? 'rgba(229,229,229, 0.9)' : props.fromHomepage && '#FAFAFA'
                          //opacity: props.comingWeeks ? '0.5' : '1'  
                        }}      
            >
                {props.recipeObj.images && props.recipeObj.images.length > 0 &&
                props.recipeObj.images.map((img, index) => {
                    return <CardMedia
                                key={index}
                                style={{opacity: props.comingWeeks ? '0.5' : '', width:'100%', paddingTop:'75%', height:'auto'}}
                                className={classes.media}
                                image={props.recipeObj.images[index]}
                                //title={props.imageTitle}
                            />
                })
                }   
                    {props.comingWeeks &&
                    <Grid item className='middle'>
                        <LockIcon className='lockIcon'/>
                    </Grid>}
                    <CardContent>
                        <Typography className={classes.title} component="p">
                            {object.nameOfDish}
                        </Typography>    

                        {props.comingWeeks ?
                        <Typography variant="body1" component="p" className={classes.titleStyles}>
                            {object.description}
                        </Typography>
                        :
                        <Tooltip title={props.info} arrow>
                            <Typography variant="body1" component="p" className={classes.titleStyles}>
                                {object.description}
                            </Typography>
                        </Tooltip>}

                        <Grid item
                            className={classes.ratings}
                            container>
                            <Grid item style={{marginRight:'32px'}}>
                                <Rating
                                    precision={0.5}
                                    size="small"
                                    value={rating}
                                    disabled={props.comingWeeks}
                                    onChange={(e) => {
                                        onRatingsClick(e.target.value);
                                    }}
                                />
                                {rating > 0 &&
                                    <ClearIcon
                                        onClick={() => {
                                            onRatingsClick(0);
                                        }}
                                        className={classes.ratingsClearIcons}
                                />

                                }
                                <Typography variant="body2" className={classes.ratingText}>
                                {`(${object.numRatings} ratings)`}
                                </Typography>
                                <Typography variant="body2"
                                    style={{display:'block', marginLeft:'-0.5'}} 
                                    className={classes.ratingText}>
                                    Average: {Math.round(object.avgRating * 100) / 100.0} / 5
                                </Typography>
                            </Grid>  

                                    
                            <Grid item className={classes.dateIcon}>
                                <div style={{display:'flex'}}>
                                    {/* <AccessTimeIcon className={classes.timeIcon} />
                                    <Typography variant="body2">
                                        {props.recipeDateUploaded}
                                    </Typography> */}
                                </div>            
                            </Grid> 
                        </Grid>
                    </CardContent>

                    <Grid item
                        container 
                        justifyContent='flex-end'
                        className={classes.overlay}>
                            <IconButton 
                                disabled={props.comingWeeks}
                                style={{background: props.comingWeeks && 'white'}}
                                onClick={onFavoriteButtonClick}
                                className={classes.overlayIcons}>
                                <FavoriteIcon color={props.isInFavList ? 'error' : 'action'} 
                                    className={classes.icon}/>    
                            </IconButton>

                            <IconButton
                                disabled={props.comingWeeks}
                                style={{background: props.comingWeeks && 'white'}}
                                disableRipple 
                                onClick={() => {
                                        props.handleDrawerOpen();
                                        props.setRecipeObj(props.recipeObj);
                                    }}
                                className={classes.overlayIcons}>
                                    <Badge badgeContent={noteArr !== undefined && noteArr.length}
                                            classes={{
                                                colorSecondary: classes.colorSecondary
                                            }}
                                            color='primary'
                                            invisible={noteArr === undefined || noteArr?.length === 0}
                                            >
                                                {
                                                    (noteArr === undefined || noteArr?.length === 0)
                                                    ?
                                                    <AssignmentOutlinedIcon  className={classes.icon}/>
                                                    :
                                                    <AssignmentIcon style={{color:'black'}} className={classes.icon}/>
                                                }   
                                    </Badge>             
                            </IconButton>   
                    </Grid>    

                    <CardActions style={{marginBottom:'18px'}}>
                        <Button 
                            onClick={showDetailsClicked}
                            className={classes.cardActions}
                            color="primary"
                            disabled={props.comingWeeks}
                            variant="outlined">
                            View Details
                        </Button>
                            
                        <Button 
                            className={classes.cardActions}
                            onClick={() => {
                                if(props.fromHomepage) {
                                    props.setSelectedState(selectedState.SHOW_RECIPES_PAGE);
                                }
                                navigate(`/recipes/${object?.id}`, {state: {recipeObj: object, fromHomePage: props.fromHomepage}})
                            }}
                            color="primary"
                            disabled={props.comingWeeks}
                            variant="contained">
                            Make this Recipe
                        </Button>    
                    </CardActions>
            </Card>
            {
                renderRecipeDetails()
            }
            </ReactCardFlip>
        )
        
    }

    //recipe design for make this recipe component
    const renderMakeThisRecipeDesign = () => {
        return (
            <Card className={classes.makeRecipeCardContainer}>
                {object.images && object.images.length > 0 &&
                object.images.map((img, index) => {
                    return <CardMedia
                                key={index}
                                width='80%'
                                className={classes.makeRecipeMedia}
                                image={object.images[index]}
                                //title={props.imageTitle}
                            />
                })
                } 
                    <CardContent className={classes.makeRecipeContent}>
                        <Grid item container>
                            <Grid item>
                                <Typography className={classes.makeRecipeTitle} component="p">
                                {object.nameOfDish}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Grid item container className={classes.makeRecipeIconContainer}>
                                    <IconButton 
                                        onClick={onFavoriteButtonClick}
                                        className={classes.makeRecipeIcons}>
                                        <FavoriteIcon color={props.isInFavList ? 'error' : 'action'} 
                                            className={classes.icon}/>    
                                    </IconButton>

                                    <IconButton
                                        disableRipple 
                                        onClick={() => {
                                                props.handleDrawerOpen();
                                            }}
                                        className={classes.makeRecipeIcons}>

                                            <Badge badgeContent={noteArr !== undefined && noteArr.length}
                                                    classes={{
                                                        colorSecondary: classes.colorSecondary
                                                    }}
                                                    color='primary'
                                                    invisible={noteArr === undefined || noteArr?.length === 0}
                                                    >
                                                        {
                                                            (noteArr === undefined || noteArr?.length === 0)
                                                            ?
                                                            <AssignmentOutlinedIcon  className={classes.icon}/>
                                                            :
                                                            <AssignmentIcon style={{color:'black'}} className={classes.icon}/>
                                                        }   
                                            </Badge>             
                                    </IconButton>   
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item container
                            spacing={1}
                            className={classes.ratings}
                            style={{marginTop: props.fromMakeThisRecipe && '4px'}}
                            >
                            <Grid item style={{marginRight:'16px'}}>
                                <Rating
                                    precision={0.5}
                                    name="simple-controlled"
                                    size="small"
                                    value={rating}
                                    onChange={(e) => {
                                        onRatingsClick(e.target.value);
                                    }}
                                />
                                {rating > 0 &&
                                    <ClearIcon
                                        onClick={() => {
                                            onRatingsClick(0);
                                        }}
                                        className={classes.ratingsClearIcons}
                                />

                                }
                                <Typography variant="body2" className={classes.ratingText}>
                                {`(${object.numRatings} ratings)`}
                                </Typography>
                                <Typography variant="body2"
                                    style={{display:'block', marginLeft:'-0.5'}} 
                                    className={classes.ratingText}>
                                    Average: {Math.round(object.avgRating * 100) / 100.0} / 5
                                </Typography>
                            </Grid>  

                            
                            <Grid item>
                                <div style={{display:'flex'}}>
                                    {/* <AccessTimeIcon className={classes.timeIcon} />
                                    <Typography variant="body2">
                                        {props.recipeDateUploaded}
                                    </Typography> */}
                                </div>            
                            </Grid>
                        </Grid>

                        <Typography variant="body2" component="p" style={{marginTop:'12px'}}>
                            {props.info}
                        </Typography>

                        {props.recipeObj.surveyURL &&  props.recipeObj.surveyURL !== '' &&            
                        <Grid item className={classes.makeRecipeActionStyle}>        
                                
                                <Grid item container direction='column' style={{marginTop:'10px'}}>            
                                    <Typography component="p" className={classes.makeRecipeText}>
                                        Did you make this recipe?
                                    </Typography>

                                    <Button color="primary" variant="contained" 
                                        onClick={() => {props.setShowSurvey(true)}}
                                        className={classes.makeRecipeButton}>
                                        Tell us what you think!
                                    </Button>
                                </Grid>
                            </Grid> 
                        }

                    </CardContent>  
                    
            </Card>
        )
        
    }

    const showDifferentCardDesigns = () => {
         if(props.fromMakeThisRecipe) {
            return renderMakeThisRecipeDesign();
         } 
        else {
            return renderRecipeCardDesign();
        }

    }
    
    return (
        <>
            <div>
                {showDifferentCardDesigns()}
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.auth.loggedInUser,
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user)),
    setSelectedState : (value) => dispatch(setSelectedState(value)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(CardComponent);