const defaultState = {
    activateInfo: {},
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_ACTIVATE_ACCOUNT_INFO':
            return {
                ...state,
                activateInfo: action.info
            };
        case 'SET_CLEAR_ACTIVATE_ACCOUNT_INFO':
            return defaultState

        default:
            return state;
    }
};
