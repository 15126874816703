import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/authUser';
import activateInfoReducer from '../reducers/activateAcc';
import programRecipesReducer from '../reducers/programRecipes';
import loadingReducer from '../reducers/loading';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Store creation:
const storeObj =  (persistedState) => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            activateAcc: activateInfoReducer,
            programsRecipes: programRecipesReducer,
            loading: loadingReducer
        }),
        persistedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default storeObj