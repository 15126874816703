import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core';
import {Grid, Typography, Button, Paper, TextField, CircularProgress, Avatar} from '@material-ui/core';
import {connect} from 'react-redux';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import {name, phone, telnum} from '../util/appUtil';
import { setUser } from '../actions/authUser';
import { database } from '../firebase/firebase';
import { useSnackbar } from 'notistack';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword, updateProfile } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@material-ui/icons/Business';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from 'firebase/storage';
import {storage} from '../firebase/firebase';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        margin:'0px auto',
        //backgroundImage: 'url(/images/vegitable-graphic.png)',
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    formContainer : {
        height:'100%', 
        marginTop:'72px',
        [theme.breakpoints.down("xs")]: {
            display:'block',
            marginBottom:'112px !important',
        },
    },
    formStyles: {
        width:'60%',
        margin:'0px auto',
        background:'#fff',
        padding:'16px',
        [theme.breakpoints.down("xs")]: {
            width:'90%',
          }
    },
    profileImg: {
        width:'110px', 
        height:'110px', 
        borderRadius:'50%',
        padding:'4px',
        position: 'relative',
        [theme.breakpoints.down("xs")]: {
            margin:'0px auto'
          },
        '& .image' : {
            display: 'flex',
            opacity: (imageUploading) => {
                return imageUploading ? 0.3 : 1
            },
            width: '100%',
            height: '100%',
            transition: '.5s ease',
            background:'rgba(0,0,0,0.8)',
            borderRadius:'50%',
            verticalAlign:'middle'
          },
          
         '& .middle': {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          },

          '& .imageUpload' : {
            opacity: 1,
            position: 'absolute',
            top: '40%',
            left: '45%',
            textAlign: 'center',
          },

          '&:hover .image' : {
            opacity: 0.3,
          },

          '&:hover .middle' : {
            opacity: 1,
            cursor:'pointer'
            
          }
    },
    userText: {
        marginLeft:'8px',
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            fontSize:'14px'
          },
    },
    formLabel: {
        marginBottom:'-4px'
    },
    topContainer: {
        margin:'16px 16px 0px 16px',
        [theme.breakpoints.down("xs")]: {
            margin:'16px auto 0px auto',
          },
    },
    text: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: '14px',
        padding: '8px 16px',
    },
    imgContainer: {
        [theme.breakpoints.down("xs")]: {
            margin:'0 auto',
          },
    },
    textContainer : {
        marginLeft:'16px',
        [theme.breakpoints.down("sm")]: {
            marginLeft:'0px',
          },
        [theme.breakpoints.down("sm")]: {
            margin: '0px auto 0px auto'
          },
        [theme.breakpoints.down("xs")]: {
            margin: '16px auto 0px auto'
          },
    },
    profileHeading : {
        fontWeight:'600',
        fontSize:'20px',
        [theme.breakpoints.down("sm")]: {
            fontSize:'16px',
          },
        [theme.breakpoints.down("xs")]: {
            fontSize:'18px',
          },
    },
    userDetails : {
        marginTop:'40px',
        [theme.breakpoints.down("sm")]: {
            marginTop:'24px',
          },
    },
    updatePassword : {
        [theme.breakpoints.down("sm")]: {
            marginTop:'24px',
          },
    },
    headingText : {
        fontSize:'18px', 
        fontWeight:'600',
        [theme.breakpoints.down("sm")]: {
            fontSize:'16px', 
          },
        [theme.breakpoints.down("xs")]: {
            fontSize:'15px', 
            textAlign:'center',
        },  
    },
    buttonStyle : {
        borderRadius:'100px',  
        width:'250px', 
        margin:'0px auto 0px auto',
        [theme.breakpoints.down("sm")]: {
            width:'200px', 
            margin:'0px auto 0px auto',
          },
    },
}));    

const Profile = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const {navigate} =  useNavigate();

    const auth = getAuth();
    let currentUser = auth.currentUser;
    console.log('current user', currentUser);
    //const storage = getStorage();

    //userInfo
    const first_name = props.loggedInUser?.firstname;
    const last_name = props.loggedInUser?.lastname;
    const phone_number = props.loggedInUser?.phone
    const address = props.loggedInUser?.deliveryAddress

    //user details
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [client, setClient] = useState('');
    const [image, setImage] = useState('');
    const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png");
    const [loading, setLoading] = useState(false);
    const [imageUploading, setImageUploading] = useState(false);
    
    const classes = useStyles(imageUploading);

    //update password
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
	const [passwordErrorText, setPasswordErrorText] = useState("Must input new and old passwords");

    const [success, setSuccess] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');
   

    const fullName = props.loggedInUser?.firstname + ' ' + props.loggedInUser?.lastname;
    const userEmail = props.loggedInUser?.email;
    const userRole = props.loggedInUser?.role;

    //get client name
    useEffect(async () => {
        let user = props.loggedInUser;
        if(!user) {
            navigate('/');
        } else {
            if (user.role === "user" && user?.client && user.client !== "") {
                const usersRef = doc(database, 'users', user.client);
                const docSnap = await getDoc(usersRef);
                console.log('user client', docSnap)
                if (!docSnap.exists) {
                    setClient("No client assigned");
                } else {
                    setClient(docSnap.data().firstname + " " + docSnap.data().lastname)
                }
            } else if (user.role === "user" && (!user?.client || user.client === "")) {
                setClient("No client assigned")
            }

        }
    }, []);

    useEffect(() => {
        setFirstName(first_name);
        setLastName(last_name);
        setPhoneNumber(phone_number);
        setDeliveryAddress(address);
    }, [props.loggedInUser]);

    useEffect(() => {
        if(success) {
            setSuccess(false); 
            setOldPassword('');
            setConfirmNewPassword('');
            setNewPassword('');
            enqueueSnackbar('Changes Saved Successfully', {
                variant:'success'
            })
        }
    }, [success]);

      useEffect(() => {

        if(props.loggedInUser?.photoURL) {
            setPhotoURL(props.loggedInUser?.photoURL);
        }

      }, [props.loggedInUser?.photoURL])

    const showMessage = (profileData) => {
        setLoading(false);
        setSuccess(true);
        const loggedInUser = {...props.loggedInUser, ...profileData}
        props.setUser(loggedInUser);
    }


    console.log('profile image', )
    const onSaveChangesClick = async () => {
        let profileData = {};
        const auth = getAuth();
        const docRef = doc(database, "users", props.loggedInUser.id);

        setLoading(true);
        if(firstName.trim() === '' || firstName === first_name) {
			setFirstName(first_name);
		} else {
			profileData.firstname = firstName;
		}
		if(lastName.trim() === '' || lastName === last_name) {
			setLastName(last_name);
		} else {
			profileData.lastname = lastName
		}
		if(phoneNumber.trim() === "" || phoneNumber === phone_number) {
			    setPhoneNumber(phone_number);
		} else  {
			profileData.phone = phoneNumber;
		}
		if(deliveryAddress.trim() === '' || deliveryAddress === address) {
			setDeliveryAddress(deliveryAddress);
		} else {
			profileData.deliveryAddress = deliveryAddress
            
		}
        //change password handling
        if(oldPassword === '' || newPassword === '' || confirmNewPassword === '') {
             //if password field is empty update user details
            await updateDoc(docRef, profileData);
            showMessage(profileData);
            return;
		} else {
            if (newPassword !== confirmNewPassword)
            {
                enqueueSnackbar('Passwords do not match', {
                    variant:'error'
                })
            }
			else {
                if(props.loggedInUser?.provider === "password") {
                    let user = auth.currentUser;
                    var credential = EmailAuthProvider.credential(
                        userEmail,
                        oldPassword
                    );
                    reauthenticateWithCredential(user, credential).then(async () => {
                         //if password change is there then update password and user details
                        await updatePassword(user, newPassword);
                        await updateDoc(docRef, profileData);
                        showMessage(profileData);
                        
                    })
                    .catch(async (error) => {
                        //if update password fails still update user details
                        setLoading(false);
                        await updateDoc(docRef, profileData);
                        const loggedInUser = {...props.loggedInUser, ...profileData}
                        props.setUser(loggedInUser);

                        if(error.code == "auth/wrong-password") {
                        enqueueSnackbar("Old password is incorrect", {variant:'error'});
                        } else if(error.code == "auth/weak-password") {
                            enqueueSnackbar("New password is too weak", {variant:'error'});
                        } else if(error.code == "auth/too-many-requests"){
                            enqueueSnackbar("Too many attempts made. Please try again later", {variant:'error'});
                        } else {
                            console.error(error.code)
                        } 
                    });
                }
            }
		}
    }

    const handleUpload = async (e) => {
        e.preventDefault();
		//console.log(e.target.files);
        const docRef = doc(database, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        
        if(e.target.files[0]) {
            setImage(e.target.files[0]);

            const re = /(?:\.([^.]+))?$/;
		    let ext = re.exec(e.target.files[0].name)[1];
            
            if(ext !== "png" && ext !== "jpg" && ext !== "jpeg") {
                enqueueSnackbar('only .png, .jpg and .jpeg format allowed', {variant: 'error'})
            } else {
                setImageUploading(true);
                const fileRef = ref(storage, 'users/' + currentUser.uid + `.${ext}`);
                const snapShot = await uploadBytes(fileRef, e.target.files[0]);
                const photoURL = await getDownloadURL(fileRef);

                const newField = props.loggedInUser;
                newField.photoURL = photoURL;
                await updateDoc(docRef, newField);
                props.setUser(newField);

                setImageUploading(false);
                enqueueSnackbar('Image Uploaded Successfully', {variant:'success'});

                console.log('snapshot', snapShot);
            }
           
        }
    }

    console.log('photo url', photoURL);

    const handleClick = async () => {
       
      }

    return (
        <div className={classes.mainContent}>
            <Grid item container  alignItems="center" 
               className={classes.formContainer}>
                    <Paper elevation={10} className={classes.formStyles}>
                        <Grid item container alignItems='center' direction='column' 
                            style={{paddingBottom:'16px'}}>

                                <Grid item container 
                                    justifyContent='flex-start'
                                    alignItems='center'
                                    className={classes.topContainer}>
                                        <Grid item xs={12} sm={4} md={4} lg={2} className={classes.imgContainer}>
                                            <Paper elevation={2} className={classes.profileImg}>
                                                <img src={photoURL}
                                                    width="100%" height="100%" className="image" />

                                                {imageUploading && <CircularProgress size={20} className='imageUpload' />}
                                                <label className='middle'>
                                                    <Typography onClick={handleClick} className={classes.text}>
                                                        Upload
                                                    </Typography>
                                                    <input type="file" onChange={handleUpload} 
                                                        style={{ display: "none" }} />      
                                                </label>   
                                            </Paper>
                                        </Grid>

                                        <Grid item md={7} lg={9}  className={classes.textContainer}>
                                            <Typography className={classes.profileHeading}>
                                                {fullName}
                                            </Typography>

                                            <Grid item container 
                                                style={{marginTop:'8px'}} 
                                                alignItems='center' >
                                                <MailIcon color='primary' fontSize='small'/>
                                                <Typography variant="body1" className={classes.userText}> 
                                                    {userEmail}    
                                                </Typography> 
                                            </Grid>

                                            <Grid item container 
                                                style={{marginTop:'8px'}}    
                                                alignItems='center'>
                                                <PersonIcon color='primary' fontSize='small'/>
                                                <Typography variant="body1" className={classes.userText}>
                                                    User Role: {userRole}    
                                                </Typography> 
                                            </Grid>

                                            {client !== '' &&
                                            <Grid item container 
                                                style={{marginTop:'8px'}}    
                                                alignItems='center'>
                                                <BusinessIcon color='primary' fontSize='small'/>
                                                <Typography variant="body1" className={classes.userText}>
                                                   Client: {client}    
                                                </Typography> 
                                            </Grid>}
                                        </Grid>
                                </Grid>

                                <Grid item container 
                                    className={classes.userDetails}>
                                
                                    <Grid item xs={12} md={6}> {/*user details part of form*/}
                                        <Typography className={classes.headingText}>
                                            User Details
                                        </Typography>

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'16px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                First Name
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                value={firstName}
                                                id="firstname"
                                                placeholder="Enter First Name"
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                onKeyPress={(e) => name(e)}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'8px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                Last Name
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                value={lastName}
                                                id="lastname"
                                                placeholder="Enter Last Name"
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => setLastName(e.target.value)}
                                                onKeyPress={(e) => name(e)}
                                                required
                                            />
                                        </Grid> 

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'8px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                Phone Number
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                value={phoneNumber}
                                                id="phone-number"
                                                placeholder="Enter Phone Number"
                                                type="tel"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => telnum(e, phoneNumber, setPhoneNumber)}
                                                onKeyPress={(e) => phone(e)}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'8px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                Delivery Address
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                id="delivery-address"
                                                value={deliveryAddress}
                                                placeholder="Enter Delivery Address"
                                                type="text"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => setDeliveryAddress(e.target.value)}
                                                required
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6} className={classes.updatePassword}> {/*update password part of form*/}
                                        <Typography className={classes.headingText}>
                                            Update Password
                                        </Typography>

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'16px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                Old Password
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                value={oldPassword}
                                                id="oldpassword"
                                                placeholder="Old Password"
                                                type="password"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                                //onKeyPress={(e) => name(e)}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'8px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                New Password
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                value={newPassword}
                                                id="newpassword"
                                                placeholder="New Password"
                                                type="password"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                               // onKeyPress={(e) => name(e)}
                                                required
                                            />
                                        </Grid> 

                                        <Grid item xs={12} sm={10} md={10} lg={11} style={{marginTop:'8px'}}>
                                            <Typography variant="subtitle1" className={classes.formLabel}>
                                                Confirm New Password
                                            </Typography>

                                            <TextField 
                                                fullWidth
                                                value={confirmNewPassword}
                                                id="confirm-password"
                                                placeholder="Confirm Password"
                                                type="password"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{style: {fontSize: 14}}}
                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                               // onKeyPress={(e) => phone(e)}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/*submit button */}
                                <Grid item xs={12} style={{marginTop:'32px'}}>
                                    <Button color='primary' variant='contained'
                                        className={classes.buttonStyle}
                                        onClick={onSaveChangesClick}> 

                                    {loading && <CircularProgress size={18} thickness={4.0} style={{marginRight:'8px', color:'white'}}/>}
                                        Save Changes
                                    </Button>
                                </Grid>
                        </Grid>
                    </Paper>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.auth.loggedInUser,
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);