import React, {useState} from 'react';
import {AppBar, Toolbar, Grid, Typography, IconButton, Container, Menu, MenuItem, Popover, Button, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import {connect} from 'react-redux';
import {logout} from '../actions/authUser';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import {setClearProgramAndRecipes, selectedState, setSelectedState} from '../actions/programsRecipes';
import {setClearActivateAccountInfo} from '../actions/activateAcc';

const useStyles = makeStyles((theme) => ({
    headerMain : {
        boxSizing:'border-box',
        height:'64px',
        //background:'blue'
    },
    navbarContainer : {
        background:'white',
        color:'#202020',
        boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    },
    grow: {
       flexGrow: 1 
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex"
        }
    },
    menuLinks : {
        display:'grid',
        color:'#202020',
        fontSize:'14px',
        padding:'0px 24px',
        fontWeight:'500',
        letterSpacing:'0.5px',
        background: "none !important",
    },
    logo : {
        position:'absolute',
        width:'100px',
        height:'100px',
        background: theme.palette.primary.main,
        borderRadius:'50%',
        top:'10px',
        left:'50% !important',
        right:'50% !important',
        transform:'translateX(-60%)',
        zIndex:'20 !important',
        cursor:'pointer',
        [theme.breakpoints.down("sm")]: {
            width:'70px',
            height:'70px',
            marginTop:'16px'
          },
    },
    profileMenu: {
        marginLeft: 'auto !important',
        paddingRight:'25px',
        cursor: 'pointer',
       
    },
    profileIcons: {
        verticalAlign:'text-bottom',
        marginRight:'2px',
    },
    MuiMenulist : {
        width:'150px'
    },
    borderBottom: {
        position:'absolute',
        left:'25px',
        width:'15px',
        height:'3px',
        background:'#009f4f'
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
    },
    MuiToolbarroot : {
        margin:'0 80px',
        justifyContent:'space-between',
        [theme.breakpoints.down("xs")]: {
            margin:'0px',
            justifyContent:'space-between'
          },
    },
}))

const Header = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const navigate = useNavigate();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const mobileMenuId = "primary-mobile-menu";

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleProfileMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const onLogout = () => {
        const auth = getAuth();
        signOut(auth);
        props.logout();
        props.setClearProgramAndRecipes();
        props.setClearActivateAccountInfo();
        navigate('/');
        
    }


    const renderMobileMenu = (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          style={{marginTop:'24px'}}
          id={mobileMenuId}
          keepMounted
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem>
            <Typography variant="body1"
                onClick={() => {
                    navigate('/');
                    props.setSelectedState(selectedState.SHOW_HOME_PAGE);
                    handleMobileMenuClose();
                }}
            >
                HOME
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1" 
                onClick={() => {
                    navigate('/recipes');
                    props.setSelectedState(selectedState.SHOW_RECIPES_PAGE);
                    handleMobileMenuClose();
                }}
            >
                RECIPES
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1" 
                onClick={() => {
                    navigate('/favorites');
                    props.setSelectedState(selectedState.SHOW_FAVORITES_PAGE);
                    handleMobileMenuClose();
                }}
            >
                FAVORITES
            </Typography>
          </MenuItem>
        </Menu>
    );

    const menuId = "primary-profile-menu";
    const rendeProfileMenu = (
        <Menu
            anchorEl={anchorEl}
            elevation={1}
            style={{marginTop:'24px'}}
            getContentAnchorEl={null}
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleProfileMenuClose}
            classes={{
                list: classes.MuiMenulist
            }}
        >
          <MenuItem onClick={() => {
                        navigate('/profile');
                        props.setSelectedState(selectedState.SHOW_PROFILE_PAGE);
                        handleProfileMenuClose();
                    }}>
            <Typography variant="body1">
                <PersonIcon 
                    fontSize="small"
                    className={classes.profileIcons}/> Profile
            </Typography>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <Typography variant="body1">
              <ExitToAppIcon
                    fontSize="small" 
                    className={classes.profileIcons}/>  Logout
            </Typography>
          </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.headerMain}>
                <AppBar elevation={0} position='relative' className={classes.navbarContainer}>
                        <Toolbar classes={{
                            root: classes.MuiToolbarroot
                            }}> 
                            <div className={classes.sectionDesktop}>
                                <div style={{position:'relative'}} >
                                    <IconButton disableRipple 
                                        onClick={() => {
                                            navigate('/');
                                            props.setSelectedState(selectedState.SHOW_HOME_PAGE);
                                        }}
                                        style={{color:(props.navBarState === selectedState.SHOW_HOME_PAGE) ? '#009f4f' : '#202020'}}
                                        className={classes.menuLinks}>
                                        HOME
                                    </IconButton>
                                    {(props.navBarState === selectedState.SHOW_HOME_PAGE) &&
                                        <Divider className={classes.borderBottom}/>  
                                    }
                                </div>

                                <div style={{position:'relative'}}>    
                                    <IconButton disableRipple 
                                        onClick={() => {
                                            navigate('/recipes');
                                            props.setSelectedState(selectedState.SHOW_RECIPES_PAGE);
                                        }}
                                        style={{color:(props.navBarState === selectedState.SHOW_RECIPES_PAGE) ? '#009f4f' : '#202020'}}
                                        className={classes.menuLinks}>
                                        RECIPES 
                                    </IconButton>
                                    {(props.navBarState === selectedState.SHOW_RECIPES_PAGE) &&
                                        <Divider className={classes.borderBottom}/>  
                                        }
                                </div>

                                <div style={{position:'relative'}}>         
                                    <IconButton disableRipple 
                                        onClick={() => {
                                            navigate('/favorites');
                                            props.setSelectedState(selectedState.SHOW_FAVORITES_PAGE)
                                        }}
                                        style={{color:(props.navBarState === selectedState.SHOW_FAVORITES_PAGE) ? '#009f4f' : '#202020'}}
                                        className={classes.menuLinks}>
                                        FAVORITES
                                    </IconButton>
                                    {(props.navBarState === selectedState.SHOW_FAVORITES_PAGE) &&
                                        <Divider className={classes.borderBottom}/>  
                                    }
                                </div>
                            </div>

                            <div style={{position:'relative'}} className={classes.sectionMobile}>
                                    <IconButton disableRipple 
                                        onClick={handleMobileMenuOpen}
                                        className={classes.menuLinks}>
                                        <MenuIcon />
                                    </IconButton>
                                    {renderMobileMenu}
                            </div>

                            <Grid className={classes.logo}
                                onClick={() => {
                                    navigate('/');
                                    props.setSelectedState(selectedState.SHOW_HOME_PAGE);
                                }}>
                                <img src='/images/eat-well-logo.png' width="100%" height="100%"/>
                            </Grid>

                            <div className={classes.profileMenu}>
                                <Typography
                                    onClick={handleProfileMenuOpen}>
                                    Menu <KeyboardArrowDownIcon style={{verticalAlign:'text-bottom', height:'18px'}}/>
                                </Typography>    
                                {rendeProfileMenu}
                            </div>
                        </Toolbar>
                </AppBar>
        </div>
    );
}

const mapStateToProps = (state) => ({
    navBarState: state.programsRecipes.navBarState
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    setClearProgramAndRecipes: () => dispatch(setClearProgramAndRecipes()),
    setSelectedState: (value) => dispatch(setSelectedState(value)),
    setClearActivateAccountInfo: () => dispatch(setClearActivateAccountInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);