import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, CircularProgress, Paper, Card, CardContent} from '@material-ui/core';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        },   
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    divider : {
        display: 'inline-block',
        borderRight: '1px solid rgba(0, 0, 0,.3)',
        margin: '0',
        marginRight: '20px',
        padding: '10px 23px 10px 0',
        fontSize: '24px',
        fontWeight: '500',
        verticalAlign: 'top',
        height:'40px'
    }
   
}));

const NotFoundPage = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.mainContent}>
            <Grid item container justifyContent='center' alignItems='center' style={{height:'100%'}}>
                <Typography style={{display:'flex', alignItems:'center'}}>
                    <span style={{fontSize:'24px', }}>
                        404
                    </span> 
                    <span className={classes.divider}> </span> 
                    <span style={{fontSize:'16px'}}>
                        This page could not be found.
                    </span>
                </Typography>
            </Grid>
        </div>
    );
}

export default NotFoundPage;