import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import CardComponent from './CardComponent';
import RecipeNotes from './RecipeNotes';
import {connect} from 'react-redux';
import moment from 'moment';
import { useIsMount } from './useIsMount';
import { database } from '../firebase/firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { setUser } from '../actions/authUser';
import {inFav, getWeeksArray, getUpdatedWeekArray} from '../util/appUtil';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    heading : {
        fontSize:'30px',
        fontWeight:'600',
        marginBottom: '16px'
    },
    loading: {
        position:'absolute',
        left:'48%',
        right:'50%',
        top:'20%'
    },
    programHeading : {
        fontSize:'22px',
        fontWeight:'600',
        color:'#585858',
        [theme.breakpoints.down('md')] : {
            fontSize:'20px', 
        },
        [theme.breakpoints.down('sm')] : {
            fontSize:'18px', 
        },
    },
    designLine : {
        height: '5px',
        borderTop: '1px solid #009f4f',
        borderBottom: '1px solid #009f4f',
        width:'100%', 
        margin: '2px auto 0 auto',
        [theme.breakpoints.down('md')] : {
            width:'68%', 
        },
        [theme.breakpoints.down('sm')] : {
            width:'55%', 
            margin: '0 8px',
        },
        [theme.breakpoints.down('xs')] : {
            width:'40%', 
            margin: '0 8px',
        }
    },
    headingIcon: {
        marginLeft:'8px',
        [theme.breakpoints.down('md')] : {
            width:'80%', 
        },
    }
}))

const Recipes = (props) => {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [recipeObj, setRecipeObj] = useState(undefined);
    const [allRecipes, setAllRecipes] = useState([]);
    const [recipesUser, setRecipesUser] = useState([]);
    const isMount = useIsMount();

    const user = props.loggedInUser;

    const currentUserProgram = props.programs[user?.program]?.programName;
    
    let start_date = props.programs[user.program]?.programStartDate;
    let numberOfWeeks = props.programs[user.program]?.numberOfWeeks;
    let weekDateArr =  getWeeksArray(start_date, numberOfWeeks);
    let dateToday = moment().format('YYYY-MM-DD');

    useEffect(() => {
        if(isMount) {
            setAllRecipes(Object.keys(props.recipes).map(function (key) {
                return props.recipes[key];
            }));
            
            }
    }, [props.recipeObj]);

    useEffect(async () => {
        if(isMount) {

            if (user.program !== "") {
                const endDate = props.programs[user.program]?.programEndDate;
                if(endDate && endDate <= Date.now()) {
                        const docRef = doc(database, "users", user.id);
                        const docSnap = await getDoc(docRef);
                        let prevPrograms = docSnap.get('prevPrograms')
                        if (!prevPrograms || prevPrograms && prevPrograms.length === 0) {
                            prevPrograms = [user.program]
                        } else if(!prevPrograms.includes(user.program)) {
                            prevPrograms.push(user.program)
                        }
                        await updateDoc(docRef, {
                            prevPrograms: prevPrograms, program: "" 
                        });

                        const new_obj = { ...props.loggedInUser, prevPrograms: prevPrograms, program: "" }
                        props.setUser(new_obj);
                    }
            }

            const keysList = props.programs[user.program]?.programRecipes ? Object.keys(props.programs[user.program]?.programRecipes) : [];
            console.log('program ids', keysList);

            if (user?.role === "user") {
                if (user.program !== "") {
                    if (
                        props.programs[user.program]?.programRecipes !== null ||
                        props.programs[user.program]?.programRecipes !== []
                    ) {
                        for(let i = 0; i < keysList.length; i++ ) {
                            let d =  props.programs[user.program].programRecipes[keysList[i]];
                            //console.log('recipes week number', d);
                            for (let j = 0; j <= numberOfWeeks; j++) {
                              //   console.log('d and j', d, j)
                                if(d === j) {
                                    let recipe =  props.recipes[keysList[i]];
                                    let weekRecipes = {
                                        week:  d,
                                        recipes: recipe,
                                    }
          
                                    recipesUser.push(weekRecipes);
                                }
                            } 
                            
                        }
                    }
                }
            }
        }
    });


    let updatedWeekArray = getUpdatedWeekArray(recipesUser, weekDateArr);

    console.log('user rating', user.ratings)

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    return (
        <div className={classes.mainContent}>
            <Grid item md={12} style={{marginTop:'54px'}}> 
               <Typography className={classes.heading}> 
                   Recipes
                </Typography>   

                {props.isLoading ? 
                    <CircularProgress className={classes.loading} color='primary' />
                    :
                    <>
                    {
                        (user.role === 'admin') ? 
                            (<Grid item container
                                //justifyContent='center'
                                spacing={6} 
                                style={{marginTop:'16px'}}>
                            {allRecipes && allRecipes.length > 0 ? allRecipes.sort((a, b) => a.dateUploaded < b.dateUploaded ? 1:-1)
                            .map((recipe, index) => {
                                return <Grid item xs={12} sm={12} md={6} lg={6} key={recipe.id}>
                                            <CardComponent 
                                                title={recipe.nameOfDish}
                                                imageSrc={recipe.images}
                                                info={recipe.description}
                                                averageRatings={recipe.avgRating}
                                                recipeRating={recipe.numRatings}
                                                recipeDateUploaded={moment(recipe.dateUploaded).format('DD MMM YYYY')}
                                                handleDrawerOpen={handleDrawerOpen}
                                                facts={recipe.recipeFact}
                                                ingredients={recipe.descriptionIngredients}
                                                recipeObj={recipe}
                                                setRecipeObj={setRecipeObj}
                                                notesList={props.loggedInUser.notes}
                                                isInFavList={inFav(recipe.id, user?.favoriteRecipes)}
                                                isFromFavoritePage={false}
                                                allergens={recipe?.recipeAllergens}
                                                userRating={
                                                    recipe.id in user?.ratings ? user?.ratings[recipe.id] : 0
                                                }
                                            />
                                        </Grid>
                            }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h1>
                                        No Recipes Found
                                    </h1>
                                </Grid>   
                            }
                        </Grid>)
                        : 
                        //current ongoing program recipes
                        (updatedWeekArray && updatedWeekArray.length > 0 || user.prevPrograms && user.prevPrograms.length > 0)
                        ?
                        <Grid item container direction='column'>
                            {updatedWeekArray && updatedWeekArray.filter((item) => item.weekStartDate < dateToday && item.weekEndDate < dateToday).length > 0 && 
                            <>
                            <Grid item style={{display:'flex', alignItems:'center'}}>
                                <Grid item>
                                    <Typography className={classes.programHeading}>
                                        {currentUserProgram}
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} className={classes.designLine}> 
                                </Grid>

                                <Grid item style={{marginLeft:'auto'}}>
                                    <img src='/images/heading-icon.svg' className={classes.headingIcon}/>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={6} style={{marginTop:'1px'}}>

                                {updatedWeekArray && updatedWeekArray.length > 0 ? 
                                updatedWeekArray.filter((item) => (item.weekStartDate < dateToday && item.weekEndDate < dateToday) || (item.weekStartDate <= dateToday && item.weekEndDate >= dateToday))
                                .map((item, index) => {
                                    console.log('recipe bro recipe', item)
                                    return item.recipes.map((recipe) => {
                                        return <Grid item xs={12} sm={12} md={6} lg={6} key={recipe.id}>
                                                <CardComponent 
                                                    title={recipe.nameOfDish}
                                                    imageSrc={recipe.images}
                                                    info={recipe.description}
                                                    averageRatings={recipe.avgRating}
                                                    recipeRating={recipe.numRatings}
                                                    recipeDateUploaded={moment(recipe.dateUploaded).format('DD MMM YYYY')}
                                                    handleDrawerOpen={handleDrawerOpen}
                                                    facts={recipe.recipeFact}
                                                    ingredients={recipe.descriptionIngredients}
                                                    recipeObj={recipe}
                                                    setRecipeObj={setRecipeObj}
                                                    notesList={props.loggedInUser.notes}
                                                    isInFavList={inFav(recipe.id, user?.favoriteRecipes)}
                                                    isFromFavoritePage={false}
                                                    allergens={recipe?.recipeAllergens}
                                                    userRating={
                                                        recipe.id in user?.ratings ? user?.ratings[recipe.id] : 0
                                                    }
                                                />
                                            </Grid>
                                    })
                                }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h1>
                                            No Recipes Found
                                        </h1>
                                    </Grid>   
                                }
                            </Grid>
                            </>}
                            
                            { //previous programs recipes
                                user?.prevPrograms  && user?.prevPrograms &&
                                <Grid  item container>
                                    {
                                        user.prevPrograms.map((program) => {
                                            let prevProgramName = props.programs[program]?.programName;
                                            let recipeIDs = Object?.keys(props.programs[program]?.programRecipes);
                                           //console.log('recipe', Object?.keys(props.programs[program]?.programRecipes));
                                            return (
                                                <Grid item xs={12} sm={12} style={{margin:'48px auto 0px auto'}} key={program.program}>
                                                <Grid item style={{display:'flex', alignItems:'center', margin:'0px 0px 8px 0px'}}>
                                                    <Grid item>
                                                        <Typography className={classes.programHeading}>
                                                            {prevProgramName}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item sm={9}  className={classes.designLine}
                                                        style={{ margin: '0px auto'}}> 
                                                    </Grid>

                                                    <Grid item style={{marginLeft:'auto'}}>
                                                        <img src='/images/heading-icon.svg' className={classes.headingIcon}/>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container spacing={6} style={{marginTop:'8px'}}>

                                                    {
                                                        recipeIDs && recipeIDs.length > 0 ? 
                                                        recipeIDs.map((id) => {
                                                            let recipe = props.recipes[id];
                                                         //  console.log('recipe', recipe,  recipeIDs);
                                                            return <Grid item xs={12} sm={12} md={6} lg={6} key={recipe.id}>
                                                                        <CardComponent 
                                                                            title={recipe.nameOfDish}
                                                                            imageSrc={recipe.images}
                                                                            info={recipe.description}
                                                                            averageRatings={recipe.avgRating}
                                                                            recipeRating={recipe.numRatings}
                                                                            recipeDateUploaded={moment(recipe.dateUploaded).format('DD MMM YYYY')}
                                                                            handleDrawerOpen={handleDrawerOpen}
                                                                            facts={recipe.recipeFact}
                                                                            ingredients={recipe.descriptionIngredients}
                                                                            recipeObj={recipe}
                                                                            setRecipeObj={setRecipeObj}
                                                                            notesList={props.loggedInUser.notes}
                                                                            isInFavList={inFav(recipe.id, user?.favoriteRecipes)}
                                                                            isFromFavoritePage={false}
                                                                            allergens={recipe?.recipeAllergens}
                                                                            userRating={
                                                                                recipe.id in user?.ratings ? user?.ratings[recipe.id] : 0
                                                                            }
                                                                        />
                                                                    </Grid>
                                                        }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <h1>
                                                                    No Recipes Found
                                                                </h1>
                                                            </Grid>   
                                                    }
                                                </Grid>
                                                </Grid>


                                            )
                                        })
                                    }

                                </Grid>    
                            }
                        </Grid> 
                        :
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                             {/* <h1>
                                No Recipes To Display
                            </h1>  */}
                        </Grid>
                    }
                         
                    {  props.loggedInUser?.notes &&
                    <RecipeNotes openDrawer={openDrawer}
                        handleDrawerClose={handleDrawerClose}
                        recipeObj={recipeObj}/>  }
                    </>
                    
                    }
            </Grid>

        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    loggedInUser: state.auth.loggedInUser,
    recipes: state.programsRecipes.recipes,
    isLoading: state.loading.isLoading,
    programs: state.programsRecipes.programs
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);