//login
export const login = (token) => ({
    type: 'LOGIN',
    token
});

export const setUser = (user) => ({
    type: 'SET_USER_ON_LOGIN',
    user
});

//logout
export const logout = () => ({
    type: 'LOGOUT'
});