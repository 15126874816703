import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Link, TextField, Paper, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { database } from '../firebase/firebase';
import { getDoc, doc, updateDoc, deleteDoc } from "firebase/firestore"; 
import {connect} from 'react-redux';
import {login, setUser, logout} from '../actions/authUser';
import ForgotPasswordPage from './ForgotPasswordPage';
import ActivateAccountPage from './ActivateAccountPage';
import { useLocation } from 'react-router-dom';
import MakeProfile from './MakeProfile';
import { useIsMount } from '../components/useIsMount';
import { setClearActivateAccountInfo } from '../actions/activateAcc';

const useStyles = makeStyles((theme) => ({
    mainBg : {
        background: theme.palette.primary.light,
        height:'100vh',
        overflow:"hidden"
    },
    logoStyle : {
        width:'100px',
        height:'100px',
        borderRadius:'50%',
    },
    loginPageHeading : {
        fontSize:'32px',
        fontWeight:"bold"
    },
    formContainer: {
        display:'flex',
        alignItems:'center',
        [theme.breakpoints.down("sm")]: {
            marginTop:'25%',
            transform:'translateY(-50px)'
          }
    },
    formStyles : {
        width:'43%',
        margin:'0px auto',
        background:'#fff',
        padding:'24px',
        [theme.breakpoints.down("xs")]: {
            width:'65%',
          }
    },
    formHeading : {
        fontSize:'16px',
        fontWeight:'600',
    },
    formLabel : {
        marginBottom:'-4px'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    submitButton : {
        width:'100%',
        borderRadius:'50px',
        padding:'10px'
    },
    forgotPwdStyle: {
        fontSize:'14px', 
        textAlign:'center',
        marginTop:'8px',
        cursor:'pointer',
        '&:hover' : {
        textDecoration: 'underline'
        }
    },
    imgContainer : {
        width:'100%',
        height:'100vh',
        backgroundColor:'#f1f2e4',
        textAlign: 'center',
        lineHeight:'100vh',
        [theme.breakpoints.down("sm")]: {
            display: "none",
          }
    },
    errorMsg: {
        marginTop: "10px", 
        color:'red', 
        textAlign:'center'
    },
    responseMsg: {
        marginTop: "10px", 
        color:'black', 
        textAlign:'center'
    },
    emailId: {
        display:'block',
        fontSize:'22px', 
        textAlign:'center', 
        marginTop:'8px', 
        padding:0
    }
}))

const LoginPage = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const codeID = location.state?.codeID;
    console.log('after coming from activate screen', codeID);
    const isMount = useIsMount();

    //Activate Account
    const [activateAccount, setActivateAccount] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorType, setErrorType] = useState(0);
    // Error types: 
	// 0 - No Error
	// 1 - Email error
	// 2 - Password error
	// 3 - Password confirm error
	// 4 - Sensitive error, do not give error info

    //forgotPassowrd
    const [forgotPassword, setForgotPassword] = useState(false);
    const auth = getAuth();
    console.log('Newly Created Page', auth);

    //create Account
    const [createAccount, setCreateAccount] = useState(false);

    //make Profile
    const [createProfile, setCreateProfile] = useState(false);

    //use Existing Account
    const [isExisting, setIsExisting] = useState(false);

    const activateAccData = () => {
        const {client, program} = props.activateInfo;
        return {
            client,
            program
        }

    }

    useEffect(() => {
        if(!isMount) {
            if(location.state?.codeID) {
                setCreateAccount(true);
                setCreateProfile(false);
            } 
        }
    }, [location.state]);

    useEffect(() => {
        if(!isMount) {
            if(auth.currentUser && !isExisting) {
                setCreateProfile(true);
                setCreateAccount(false);
            } 
        }
    }, [auth.currentUser]);

    useEffect(() => {
        if(!isMount) {
            if(isExisting) {
                setCreateProfile(false);
                setCreateAccount(false);
            } 
        }
    }, [isExisting]);

    const handleSubmit = (e) => {
        if(email === '' || password === '') {
            let errText1 =  "Please enter an email address";
            let errText2 = "Please enter password"
            setError(email === '' ? errText1 : errText2);
            setErrorType(email === '' ? 1 : 2);
        } else {    
                setLoading(true);
                if(createAccount) {
                    if(confirmPassword === '') {
                        setError("Please confirm your password");
                        setErrorType(3);
                    } else if(password !== confirmPassword) { 
                        setError("Passwords do not match");
                        setErrorType(3);
                    } else {
                       createUserWithEmailAndPassword(auth, email, password)
                       .then(async (userCredential) => {
                            console.log('new user created in', userCredential);
                            //props.login(userCredential.user.accessToken);
                                // If signing in again, track the number of visits in the user's document
                                const docRef = doc(database, "users", userCredential.user.uid);
                                const docSnap = await getDoc(docRef);
                                if (docSnap.exists()) {
                                    var timesVisited = docSnap.data()?.timesVisited + 1;
                                    await updateDoc (docRef, {timesVisited: timesVisited});
                                }
                                navigate('/profile/makeProfile');
                                setCreateAccount(false);
                                setLoading(false);
                       // Display the proper error message based on the error code returned by the function. 
                       // See https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
                        }).catch((err) => {
                            var m = "";
                            switch(err.code) {
                            case "auth/email-already-in-use":
                                m = "Account with given email address already exists";
                                setErrorType(1);
                                break;
                            case "auth/invalid-email":
                                m = "Email address is not valid";
                                setErrorType(1);
                                break;
                            case "auth/weak-password":
                                m = "Password is too weak";
                                setErrorType(2);
                                break;
                            }
                            if(m) {setError(m)}
                            setLoading(false);
                        });    
                    }    
                } else {
                    signInWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        // Signed in 
                        //console.log('user logged in', userCredential, isExisting);
                        if(isExisting) {
                                const docRef = doc(database, 'users', userCredential.user.uid);
                                const docRef1 = doc(database, 'codes',  props.activateInfo.codeID);
                                const docSnap = await getDoc(docRef);
                                if(docSnap.exists()) {
                                    let program = docSnap.data()["program"];
                                        if(program === '') {
                                            setCreateProfile(false);
                                            setCreateAccount(false);
                                            let userData = Object.assign({}, activateAccData());
                                            await updateDoc(docRef, userData).then(() => {
                                                props.login(userCredential.user.accessToken);
                                                navigate('/');
                                                setLoading(false);  
                                               // deleteDoc(docRef1);
                                            }).
                                            catch((error) => {
                                                console.log(error);
                                                props.login(userCredential.user.accessToken);
                                                navigate('/');
                                                setLoading(false); 
                                            })
                                            
                                        } else {
                                            setCreateProfile(false);
                                            setIsExisting(false);
                                            signOut(auth);
                                            props.logout();
                                            props.setClearActivateAccountInfo();
                                            navigate('/');
                                            console.log('This account has an active program. Joining multiple active programs is not allowed.');
                                            setErrorType(4);
                                            setError('This account has an active program. Joining multiple active programs is not allowed.');
                                            setLoading(false);
                                        }
                                    
                                }
                        } else {
                        props.login(userCredential.user.accessToken);
                        // If signing in again, track the number of visits in the user's document
                        const docRef = doc(database, "users", userCredential.user.uid);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            var timesVisited = docSnap.data()?.timesVisited + 1;
                            await updateDoc(docRef, {timesVisited: timesVisited});
                            setLoading(false);
                        }
                    }
                })
                .catch((err) => {
                    var m = "";
                    switch(err.code) {
                        case "auth/invalid-email":
                            m = "Email address is not valid";
                            setErrorType(1);
                            break;
                        case "auth/user-disabled":
                            m = "User account has been disabled";
                            setErrorType(4);
                            break;
                        case "auth/user-not-found":
                        case "auth/wrong-password":
                            m = "Incorrect email or password";
                            setErrorType(4);
                            break;
                        default:
                            console.log(err)
                    }
                    if(m) {
                        setError(m);
                    }
                    setLoading(false);
                });
            }
        }
    }

    const loginForm = () => {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body2" className={classes.formLabel}>
                        Email Address
                    </Typography>

                    <TextField 
                        fullWidth
                        id="email"
                        placeholder="Enter email address"
                        type="text"
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{style: {fontSize: 14}}}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorType(0);
                        }}
                        error={errorType === 1 || errorType === 4 } 
                        helperText={errorType === 1  ? error : ""}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'8px'}}>
                    <Typography variant="body2" className={classes.formLabel}>
                        Password
                    </Typography>

                    <TextField 
                        fullWidth
                        id="password"
                        placeholder="Enter Password"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{style: {fontSize: 14}}}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrorType(0);
                        }}
                        error={errorType === 2 || errorType === 4 } 
                        helperText={errorType === 2  ? error : ""}
                        onKeyPress={(e) => {
                            //consoleToLog(`Pressed keyCode ${ev.key}`);
                            if (e.key === 'Enter') {
                                handleSubmit();
                              e.preventDefault();
                            }
                          }}
                    />
                </Grid> 

                {createAccount &&
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'8px'}}>
                        <Typography variant="body2" className={classes.formLabel}>
                           Confirm Password
                        </Typography>

                        <TextField 
                            fullWidth
                            id="confirm-password"
                            placeholder="Confirm Password"
                            type="password"
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{style: {fontSize: 14}}}
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setErrorType(0);
                            }}
                            error={errorType === 3 || errorType === 4 } 
                            helperText={errorType === 3  ? error : ""}
                            onKeyPress={(e) => {
                                //consoleToLog(`Pressed keyCode ${ev.key}`);
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                  e.preventDefault();
                                }
                              }}
                        />
                    </Grid>
                }

                {errorType == 4 && 
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                     <Typography variant="subtitle1" className={classes.errorMsg}>
						{error}
					 </Typography>
                 </Grid>
				}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControlLabel
                        classes={{
                            root: classes.MuiFormControlLabelroot
                        }}
                        control={
                        <Checkbox
                            name="checkedB"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                        }
                        label="Remember Me"
                    />
                </Grid>   

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                    >
                        {loading ? <CircularProgress size={20} thickness={4.0} style={{marginRight:'8px', color:'white'}}/> :
                        <ExitToAppIcon style={{marginRight:'8px'}}/>} SUBMIT
                    </Button>    
                </Grid>
                
                {createAccount ?
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.forgotPwdStyle}
                        onClick={() => {
                            setIsExisting(true); 
                            setCreateAccount(false);
                        }}
                    >
                        Use Existing Account
                    </Typography>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.forgotPwdStyle}
                        onClick={() => {setForgotPassword(true)}}
                    >
                        Forgot Password ? 
                    </Typography>
                </Grid>
                }   
            </>
        )
    }

    const displayForms = () => {
        if(activateAccount) {
            return <ActivateAccountPage 
                        errorMsg={classes.errorMsg}
                        formLabel={classes.formLabel}
                        submitButton={classes.submitButton}
                        setActivateAccount={setActivateAccount}
                    />

        } else if(forgotPassword) {
            return <ForgotPasswordPage auth={auth} 
                        setForgotPassword={setForgotPassword}
                        formLabel={classes.formLabel}
                        submitButton={classes.submitButton}
                        forgotPwdStyle={classes.forgotPwdStyle}
                        responseMsg={classes.responseMsg}
                        activateAccount={activateAccount}
                    />
        } else if(createProfile) {
            return <MakeProfile formLabel={classes.formLabel}
                submitButton={classes.submitButton}
                errorMsg={classes.errorMsg}
                
                />
        } else {
            return loginForm()
        }
    }

    const displayFormHeading = () => {
        if(activateAccount) {
            return "Input your organization-provided activation \ncode to register:"
        } else if(forgotPassword) {
            return "Reset Your Password"
        } else if(createProfile) {
            return "Please complete your profile to proceed!"
        } else if(createAccount) {
            return "Create An Account"
        } 
            else {
            return "Already Registered? Sign in to proceed:"
        }

    }

    console.log('create Account', createAccount);
    return (
        <div className={classes.mainBg}>
            <Grid item container alignItems="center">
                <Grid item
                    className={classes.formContainer}
                    xs={12} sm={12} md={6} lg={6} >

                    <Grid item container direction="column">
                        <Grid item style={{margin:'0px auto 28px auto'}}>
                            <Grid item
                                alignItems='center' 
                                container 
                                direction="column">
                                <Grid item 
                                    className={classes.logoStyle}>
                                    <img src='/images/eat-well-logo.png'/>
                                </Grid>

                                <Grid item>
                                    <Typography className={classes.loginPageHeading}>
                                        Welcome to EatWell!
                                       <span className={classes.emailId}>
                                        {createProfile && auth.currentUser?.email}    
                                       </span> 
                                    </Typography>
                                </Grid>    
                            </Grid>
                        </Grid> 

                        <Grid item>
                            <Paper elevation={10}
                                className={classes.formStyles}>
                                <Typography className={classes.formHeading} style={{textAlign:'center'}}> 
                                    {displayFormHeading()}
                                </Typography>

                                <Grid item container 
                                    spacing={1}
                                    style={{marginTop:'16px'}}>

                                    {displayForms()}

                                </Grid>
                                
                            </Paper>

                            {!forgotPassword &&
                            <Grid item container
                                style={{marginTop:'18px'}} 
                                justifyContent="center" >
                                {
                                activateAccount ?
                                    <Typography>
                                        Already a user? 
                                        <Link onClick={() => {
                                            setActivateAccount(false);
                                            navigate('/');
                                        }} 
                                            style={{cursor:'pointer', marginLeft:'2px'}}>
                                            Login
                                        </Link> here.
                                    </Typography>
                                    :
                                    !createProfile &&    
                                    <Typography>
                                        New user?  
                                        <Link onClick={() => {
                                            setActivateAccount(true);
                                            navigate('/activate');
                                        }} 
                                            style={{cursor:'pointer', marginLeft:'2px'}}>
                                            Activate Account
                                        </Link> here.
                                    </Typography>
                                }           
                            </Grid>}    
                        </Grid>    
                    </Grid>   
                </Grid>

                <Grid item md={6} lg={6} className={classes.imgContainer}>
                    <img src="/images/vegitable-graphic.png"
                        width="85%"
                        style={{verticalAlign:'middle'}}/>       
                </Grid>    
            </Grid>    
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    loggedInUser: state.auth.loggedInUser,
    activateInfo: state.activateAcc.activateInfo
});

const mapDispatchToProps = (dispatch) => ({
    login: (token) => dispatch(login(token)),
    setUser: (user) => dispatch(setUser(user)),
    logout: () => dispatch(logout()),
    setClearActivateAccountInfo: () => dispatch(setClearActivateAccountInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);