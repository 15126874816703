export const setAllPrograms = (programs) => ({
    type: 'SET_ALL_PROGRAMS',
    programs
});

export const setAllRecipes = (recipes) => ({
    type: 'SET_ALL_RECIPES',
    recipes
});

export const setClearProgramAndRecipes = () => ({
    type: 'CLEAR_PROGRAM_AND_RECIPES',
});


export const selectedState = {
    SHOW_HOME_PAGE  : 'home',
    SHOW_RECIPES_PAGE : 'recipes',
    SHOW_FAVORITES_PAGE : 'favorites',
    SHOW_PROFILE_PAGE : 'profile'
};

export const setSelectedState = (value) =>  ({
    type: 'SET_SELECTED_STATE',
    value
});