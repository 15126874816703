import React, {useState} from 'react';
import { sendPasswordResetEmail  } from "firebase/auth";
import { Button, Grid, Typography, TextField, CircularProgress} from '@material-ui/core';

const ForgotPasswordPage = (props) => {
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [forgotPasswordError, setForgotPasswordError] = useState('');
    const [forgotPasswordErrorType, setForgotPasswordErrorType] = useState(0);
    const [loading, setLoading] = useState(false);

    //const auth = getAuth();

    const handleForgotPassword = async (e) => {
        if(forgotPasswordEmail === '') {
            setForgotPasswordError("Please enter an email address");
            setForgotPasswordErrorType(1);
          } else {
              try {
                  setForgotPasswordErrorType(0);
                  setForgotPasswordError("");
                  setLoading(true);
                  await sendPasswordResetEmail(props.auth, forgotPasswordEmail);
                  setForgotPasswordError("Successfully sent reset password link to your email! Please check your inbox for further instructions.")
                  setForgotPasswordErrorType(2);
              } catch {
                  setForgotPasswordError("Please enter a valid email address")
                  setForgotPasswordErrorType(1);
              }
              setForgotPasswordEmail("");
              setLoading(false);
              
          }
          e.preventDefault();

    }

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" className={props.formLabel}>
                    Email Address
                </Typography>

                <TextField 
                    fullWidth
                    id="forgot-password-email"
                    placeholder="Enter email address"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    value={forgotPasswordEmail}
                    InputLabelProps={{style: {fontSize: 14}}}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    error={forgotPasswordErrorType === 1} 
                    helperText={forgotPasswordErrorType === 1  ? forgotPasswordError : ""}
                    onKeyPress={(e) => {
                        //consoleToLog(`Pressed keyCode ${ev.key}`);
                        if (e.key === 'Enter') {
                         handleForgotPassword();
                          e.preventDefault();
                        }
                      }}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={props.submitButton}
                        onClick={handleForgotPassword}
                    >
                        {loading && <CircularProgress size={20} thickness={4.0} style={{marginRight:'8px', color:'white'}}/>}
                        Send Reset Link
                    </Button>    
            </Grid>

            {(forgotPasswordErrorType == 2) && 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="subtitle1" 
                    className={props.responseMsg}>
                    {forgotPasswordError}
                    </Typography>
                </Grid>
            }
                
            <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={props.forgotPwdStyle}
                        onClick={() => props.setForgotPassword(false)}
                    >
                        Back to Log In
                    </Typography>
            </Grid>   
        </>
    );
}

export default ForgotPasswordPage;