import React, {useState, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Grid, Typography, CircularProgress, Tooltip, Card, CardMedia, CardContent} from '@material-ui/core';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import moment from 'moment';
import {getWeeksArray, getUpdatedWeekArray} from '../util/appUtil';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateRangeIcon from '@material-ui/icons/DateRange';
import {inFav} from '../util/appUtil';
import CardComponent from './CardComponent';
import RecipeNotes from './RecipeNotes';
import { database } from '../firebase/firebase';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import NoHomePage from './NoHomePage';
import { setUser } from '../actions/authUser';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        },   
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    heading : {
        fontSize:'28px',
        fontWeight:'600',
        marginBottom: '16px'
    },
    loading: {
        position:'absolute',
        left:'48%',
        right:'50%',
        top:'20%'
    },
    MuiStepperroot : {
        backgroundColor: theme.palette.primary.light,
        padding:'8px 0px',
    },
    accordionHeading : {
        fontSize:'18px',
        fontWeight:'600',
        display:'flex',
        alignItems:'center'
    },
    accordionContainer : {
        marginBottom:'24px',
        borderRadius: "0px",
        borderColor: "none",
        backgroundColor: "none",
        boxShadow:'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        '&:last-child' : {
            marginBottom: '0px'
        }
    },
    currentWeekStyles : {
        background:'#009f4f', 
        width:'90px', 
        display:'block',  
        padding:'0 2px', 
        color:'white'
    },
    progress:{
        position: 'relative',
        marginBottom: '20px',
        [theme.breakpoints.down("sm")]: {
            width:'200%',
            marginBottom: '0px',
        },
        // [theme.breakpoints.down("xs")]: {
        //     width:'250%',
        //     marginBottom: '0px',
        // },
        
    },
    progress_bar: {
        position: 'absolute',
        background: 'green',
        height: '4px',
        width: '0%',
        top: '50%',
        left: '1%',
    },
    programNum : {
        margin: '0',
        padding:'0',
        listStyle:'none',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            width:'100%',
        },
        // [theme.breakpoints.down("xs")]: {
        //     width:'100%',
        // },
        "&::before": {
            content: `''`,
            background: '#ccc',
            position: 'absolute',
            top: '50%',
            left: '0',
            height: '4px',
            width: '100%',
            zIndex: '-1'
          },
          [theme.breakpoints.down("sm")]: {
            width:'100%',
        },
          [theme.breakpoints.down("xs")]: {
            width:'100%',
        },
        "&::after": {
            content:`''`,
            background: '#ccc',
            position: 'absolute',
            top: '50%',
            left: ({width}) => width,
            width: ({width}) => `calc(100% - ${width})`,
            height: '4px',
            zIndex: '1',
            [theme.breakpoints.down("sm")]: {
                width:'100%',
            },
            // [theme.breakpoints.down("xs")]: {
            //     width:'100%',
            // },
          }
    },
    steps: {
        borderRadius: '50%',
        width: '20px',
        height: "20px",
        lineHeight: '20px',
        marginTop: '4px',
        textAlign: 'center',
        backgroundColor: 'lightgray',
        fontSize: '14px',
        color: 'white',
        position: 'relative',
        border: '1px solid white',
        zIndex: '2',
        [theme.breakpoints.down("sm")]: {
            width: '15px',
            height: "15px",
            lineHeight: '15px',
            textAlign: 'center',
        },
        [theme.breakpoints.down("xs")]: {
            width: '12px',
            height: "12px",
            lineHeight: '12px',
            marginTop: '2px',
            textAlign: 'center',
        },
        '&.active' : {
            border: '1px solid #009f4f',
            backgroundColor: 'white',
            color: '#fff'
        },
        '&.completed' : {
            border: '1px solid #009f4f',
            backgroundColor: '#009f4f',
            color: '#fff'
        },
    },
    progressContainer: {
        padding:'0 24px',
        [theme.breakpoints.down("sm")]: {
            overflowX: 'auto', 
            width:'100%',
            height:'70px',
        }
    },
    toolTip:{
        position: 'relative',
        display: 'inline-block',
        borderBottom: '1px dotted black',
        '& .toolTipText' : {
            width: '100px',
            backgroundColor: '#009f4f',
            color: '#fff',
            textAlign: 'center',
            borderRadius: '6px',
            padding: '5px 0',
            position: 'absolute',
            zIndex: '1',
            top: '150% !important',
            left: '50%',
            marginTop: '20px',
            marginLeft: '-50px',
            fontSize: '12px',
            [theme.breakpoints.down("xs")]: {
                width: '60px',
                top: '160% !important',
                left:"-30px",
                marginLeft:0,
            }
        },
        '& .toolTipText::after': {
            content: `""`,
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: 'transparent transparent #009f4f transparent',
        }
    },
    recipeScheduleContainer : {
        marginTop:'38px',
        [theme.breakpoints.down("sm")]: {
            marginTop:'0px',
        }
    },
    programDetailsContainer: {
        display: 'flex',
        padding: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        [theme.breakpoints.down("sm")]: {
            height:'auto !important',
        },
        [theme.breakpoints.down("xs")]: {
            display:'flex',
            padding:'0px',
            flexDirection:'column',
            height:'auto !important',
        }
     },
     programDetailsContent : {
        paddingTop:'0px',
        marginLeft:'8px',
       [theme.breakpoints.down("xs")]: {
           marginLeft:'0px',
       }
    },
    programDetailsTitle: {
        fontSize:'1.5rem',
        fontWeight:'500',
        marginTop:'-3px',
        [theme.breakpoints.down(1200)]: {
            marginTop:'0px',
            fontSize:'1.2rem',
          },
        [theme.breakpoints.down("sm")]: {
            marginTop:'0px',
            fontSize:'1.1rem',
          },
        [theme.breakpoints.down("xs")]: {
            marginTop:'0px',
            fontSize:'1rem',
          }
     },
     programDetailsMedia: {
        width: "80%",
        marginLeft:'auto',
        ["@media (min-width:600px)"]: {
            width: "15%",
            minWidth: "15%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "32%",
            minWidth: "32%",
            height: "auto"
          },
        [theme.breakpoints.down("xs")]: {
            display:'none',
            // height: '80%',
            // width: "100%",
            // paddingTop: "50.25%",
            // position:'relative',
            // bottom:'8px'
        },  
    },
     programNames : {
        fontSize:'18px', 
        fontWeight:'600',
        [theme.breakpoints.down("sm")]: {
            fontSize:'16px',
          },
     },
     numOfWeeks : {
         display:'inline-block',
        fontSize:'16px', 
        fontWeight:'600', 
        background:'#ebf8f1', 
        padding:'8px 4px',
        width:'100%',
        borderRadius:'4px',
        textAlign:'center'
     },
     wavingHandImage : {
        width: '60px', 
        height: "60px",
        verticalAlign:'text-bottom',
        marginLeft:'-8px',
        [theme.breakpoints.down("xs")]: {
            display:'none',
            // height: '80%',
            // width: "100%",
            // paddingTop: "50.25%",
            // position:'relative',
            // bottom:'8px'
        },
     }
}));

const Home = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const user = props.loggedInUser;

    const [openDrawer, setOpenDrawer] = useState(false);
    const [recipeObj, setRecipeObj] = useState(undefined);
    const [client, setClientObj] = useState(undefined);
    const [activeStep, setActiveStep] = useState(0);

    //user and program details
    let fullName = user?.firstname + " " + user.lastname
    let programName = props.programs[user.program]?.programName;
    let start_date = props.programs[user.program]?.programStartDate;
    let numberOfWeeks = props.programs[user.program]?.numberOfWeeks;
    let dateToday = moment().format('YYYY-MM-DD');

    //check user program end date
        if (user.program !== "") {
            const endDate = props.programs[user.program]?.programEndDate;
            console.log('end date', endDate);
            if(endDate && endDate <= Date.now()) {
                    const docRef = doc(database, "users", user.id);
                    const docSnap = getDoc(docRef);
                    let prevPrograms = docSnap.data?.get('prevPrograms');
                    if (!prevPrograms || prevPrograms && prevPrograms.length === 0) {
                        prevPrograms = [user.program]
                    } else if(!prevPrograms.includes(user.program)) {
                        prevPrograms.push(user.program)
                    }
                     updateDoc(docRef, {
                        prevPrograms: prevPrograms, program: "" 
                    });
    
                    const new_obj = { ...props.loggedInUser, prevPrograms: prevPrograms, program: "" }
                    props.setUser(new_obj);
                }
        }
    

    useEffect(async () => {
        getStepPosition();
        const docRef = doc(database, "users", user.client);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            setClientObj(docSnap.data());
        }    

    }, []);

    let weekDateArr =  getWeeksArray(start_date, numberOfWeeks);

    console.log('week date array', weekDateArr);

    const getStepPosition = () => {
       // let dateToday = moment().format('YYYY-MM-DD');
        let newArr = weekDateArr.filter((week) => week.weekStartDate <= dateToday && week.weekEndDate > dateToday);
        console.log('newArr returned', newArr)
        const weekObj = Object.assign({}, ...newArr);
        console.log('weekObj returned', weekObj)
         if(weekObj !== undefined) {
             console.log('Number returned', Number(weekObj.week) - 1)
            return isNaN(Number(weekObj.week) - 1) ? 0 : Number(weekObj.week) - 1;
        }
    }


    let activeWidth = (((getStepPosition()) / (weekDateArr.length - 1)) * 99 + "%");
    //console.log(activeWidth, activeStep);

    const progressWidth = {
        width: `${activeWidth}`
    }
    const newClass = useStyles(progressWidth);
      
    let weeklyRecipes = [];
      const keysList = props.programs[user.program]?.programRecipes ? Object.keys(props.programs[user.program]?.programRecipes) : [];
      if(user?.program !== '' && user?.role === "user") {
          if (
              props.programs[user.program]?.programRecipes != null ||
              props.programs[user.program]?.programRecipes != []
          ) {
              for(let i = 0; i < keysList.length; i++ ) {
                  let d =  props.programs[user.program].programRecipes[keysList[i]];
                  //console.log('recipes week number', d);
                  for (let j = 0; j <= numberOfWeeks; j++) {
                    //   console.log('d and j', d, j)
                      if(d === j) {
                          let recipe =  props.recipes[keysList[i]];
                          let weekRecipes = {
                              week:  d,
                              recipes: recipe,
                          }

                          weeklyRecipes.push(weekRecipes);
                          // setWeeklyRecipes(oldRecipes => [...oldRecipes, {
                          //         [d]:  recipe
                          //     }]
                          // ) ;
                      }
                  } 
                  
              }
          }
      }

    //console.log('weekly recipes', weeklyRecipes);  

    let updatedWeekArray = getUpdatedWeekArray(weeklyRecipes, weekDateArr);
    let previousWeekArr = updatedWeekArray.filter((item) => item.weekStartDate <= dateToday && item.weekEndDate <= dateToday);
    let currentWeekArr = updatedWeekArray.filter((item) => item.weekStartDate <= dateToday && item.weekEndDate > dateToday);
    let comingWeeksArr =  updatedWeekArray.filter((item) => item.weekStartDate > dateToday && item.weekEndDate >= dateToday);

    if(start_date > Date.now()) {
        updatedWeekArray = [...previousWeekArr, ...currentWeekArr, ...comingWeeksArr];
    } else {
        updatedWeekArray = [...previousWeekArr, ...currentWeekArr, comingWeeksArr && comingWeeksArr.length > 0 && comingWeeksArr[0]];
    }
    console.log('coming week array', comingWeeksArr);
    console.log('updatedWeekArray', updatedWeekArray);
      
    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const displayStepStyles = (currentWeek, previousWeek, comingWeeks) => {
        //console.log('current week', currentWeek);
        if(currentWeek) {
            return `${classes.steps} active`;
        } else if (previousWeek) {
            return `${classes.steps} completed`
        } else {
            return `${classes.steps}`
        }

    }

    console.log('active width', activeWidth);

    return (
        props.loggedInUser?.program === '' ? 
        <NoHomePage />
        :
        <div className={classes.mainContent}>
            <Grid item style={{marginTop:'56px'}}> 
                { props.isLoading ? 
                    <CircularProgress size={35} className={classes.loading} color='primary'/> 
                    :
                    <Grid item container direction="column" justifyContent='space-evenly'>
                        <Grid item xs={12} style={{marginBottom:'24px'}}>
                            <Card className={classes.programDetailsContainer}>
                                {/* {props.recipeObj.images && props.recipeObj.images.length > 0 &&
                                props.recipeObj.images.map((img, index) => {
                                    return <CardMedia
                                                key={index}
                                                width='80%'
                                                className={classes.makeRecipeMedia}
                                                image={props.recipeObj.images[index]}
                                                //title={props.imageTitle}
                                            />
                                })
                                }  */}
                                    <CardMedia
                                        width='80%'
                                        className={classes.makeRecipeMedia}
                                        image='/images/waving_hand.png'
                                        //title={props.imageTitle}
                                    />  
                                    <CardContent className={classes.programDetailsContent}>
                                        <Grid item container spacing={1} direction="column">
                                            <Grid item style={{display:"flex", alignItems:'center'}}>
                                                <img src="/images/waving_hand.jpg" className={classes.wavingHandImage}/>
                                                <Typography className={classes.programDetailsTitle} component="p">
                                                  Hi <b>{fullName}</b>, Welcome to EatWell
                                                </Typography>
                                            </Grid>

                                            <Grid item>
                                                <Typography className={classes.programNames} component="p">
                                                {client?.firstname + " " + client?.lastname}
                                                </Typography>
                                            </Grid>

                                            <Grid item>
                                                <Typography className={classes.programNames} component="p">
                                                {programName}
                                                </Typography>
                                            </Grid>

                                            <Grid item sm={6} lg={3}>
                                                <Typography className={classes.numOfWeeks} color="primary" component="p">
                                                <DateRangeIcon style={{marginRight: "4px", verticalAlign:'text-bottom'}}/>  {numberOfWeeks} Weeks
                                                </Typography>
                                            </Grid>
                                        </Grid>    

                                    </CardContent> 

                                     <CardMedia
                                        width='80%'
                                        className={classes.programDetailsMedia}
                                        image='/images/welcome-user-graphic.svg'
                                        //title={props.imageTitle}
                                    />   
                            </Card>
                        </Grid>

                        <Grid item xs={12} >
                            <Typography className={classes.heading}> 
                                Progress Tracker
                            </Typography> 
                            <div className={classes.progressContainer}>
                                <div className={classes.progress}>
                                    <div  className={classes.progress_bar} style={{width: `${activeWidth}`}}></div>
                                        <ul className={newClass.programNum} >
                                            {weekDateArr.map(({week, weekStartDate, weekEndDate}) => {
                                                let dateToday = moment().format('YYYY-MM-DD');
                                                let currentWeek = (weekStartDate <= dateToday && weekEndDate >= dateToday);
                                                let previousWeek = (weekStartDate <= dateToday && weekEndDate <= dateToday);
                                                let comingWeeks = (weekStartDate > dateToday && weekEndDate >= dateToday);

                                                const stDateObject = new Date(weekStartDate);
                                                let stDateMth = (stDateObject.getMonth() + 1).toString().padStart(2, '0');
                                                let stDateDay = stDateObject.getDate().toString().padStart(2, '0');

                                                const endDateObject = new Date(weekEndDate);
                                                let endDateMth = (endDateObject.getMonth() + 1).toString().padStart(2, '0');
                                                let endDateDay = endDateObject.getDate().toString().padStart(2, '0');

                                                return <li className={displayStepStyles(currentWeek, previousWeek, comingWeeks)} 
                                                            key={week}>
                                                            <div className={currentWeek && classes.toolTip}>
                                                                <span className={currentWeek && 'toolTipText'}>
                                                                    {currentWeek ? `Week ${week}` : ''} <br></br>
                                                                    {currentWeek ? `${stDateMth}/${stDateDay} to ${endDateMth}/${endDateDay}` : ''}
                                                                </span>
                                                            </div>
                                                        </li>

                                            })}  
                                        </ul>     
                                </div>
                            </div>

                        </Grid>


                        <Grid item xs={12} className={classes.recipeScheduleContainer} style={{marginTop:'38px'}}>
                            <Typography className={classes.heading}> 
                                Recipe Schedule
                            </Typography>

                            <Grid item xs={12} style={{margin:'16px 0px 32px 0px'}}>    
                                {   updatedWeekArray && updatedWeekArray.length > 0 &&
                                    updatedWeekArray?.sort((a,b) => b.week - a.week)?.map(({week, recipes, weekStartDate, weekEndDate}) => {
                                        //let dateToday = moment().format('YYYY-MM-DD');
                                        if(week!==undefined && recipes!==undefined && weekStartDate!==undefined && weekEndDate!==undefined) {
                                        let currentWeek = (weekStartDate <= dateToday && weekEndDate >= dateToday);
                                        return <div  key={week} style={{marginBottom:'24px'}}>
                                                    {currentWeek &&
                                                        <Typography variant="body2" 
                                                            className={classes.currentWeekStyles}>
                                                            Current Week
                                                        </Typography>
                                                    }
                                                <Grid item  className={classes.accordionContainer}
                                                 //style={{border: currentWeek ? '4px solid #009f4f' : 'none'}}   
                                                >
                                                
                                                <Accordion elevation={2} square={false}
                                                defaultExpanded={currentWeek}
                                                style={{
                                                    borderRadius: "0px",
                                                    //border: "none",
                                                    backgroundColor: "none",
                                                    boxShadow:'none',
                                                    border: currentWeek ? '4px solid #009f4f' : 'none'
                                                }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon 
                                                            style={{padding:'5px', 
                                                                    boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                    borderRadius:'4px'
                                                                }}/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header" >
                                                            <Typography className={classes.accordionHeading}>
                                                                <DateRangeIcon style={{marginRight: "8px"}}/>  
                                                                {` Week ${week} Recipes`}
                                                            </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                    <Grid item container
                                                            //justifyContent='center'
                                                            spacing={6} >
                                                        {recipes && recipes.length > 0 && recipes.sort((a, b) => a.dateUploaded < b.dateUploaded ? 1:-1)
                                                        .map((recipe, index) => {
                                                            return <Grid item xs={12} sm={12} md={6} lg={6} key={recipe.id}>
                                                                        {/* {console.log('123443', recipe)} */}
                                                                        <CardComponent 
                                                                            title={recipe.nameOfDish}
                                                                            imageSrc={recipe.images}
                                                                            info={recipe.description}
                                                                            averageRatings={recipe.avgRating}
                                                                            recipeRating={recipe.numRatings}
                                                                            recipeDateUploaded={moment(recipe.dateUploaded).format('DD MMM YYYY')}
                                                                            handleDrawerOpen={handleDrawerOpen}
                                                                            facts={recipe.recipeFact}
                                                                            ingredients={recipe.descriptionIngredients}
                                                                            recipeObj={recipe}
                                                                            setRecipeObj={setRecipeObj}
                                                                            notesList={user?.notes}
                                                                            isInFavList={inFav(recipe.id, user?.favoriteRecipes)}
                                                                            isFromFavoritePage={false}
                                                                            allergens={recipe?.recipeAllergens}
                                                                            fromHomepage={true}
                                                                            comingWeeks={weekStartDate > dateToday && weekEndDate > dateToday}
                                                                            userRating={
                                                                                recipe.id in user?.ratings ? user?.ratings[recipe.id] : 0
                                                                            }
                                                                       />
                                                                    </Grid>
                                                        })
                                                        }
                                                    </Grid>    
                                                    </AccordionDetails>
                                                </Accordion>
                                                </Grid>
                                                </div>
                                        }
                                    })
                                }
                            </Grid>    
                        </Grid>   
                    </Grid> 
                } 
                 {  props.loggedInUser?.notes &&
                <RecipeNotes openDrawer={openDrawer}
                    handleDrawerClose={handleDrawerClose}
                    recipeObj={recipeObj}/>  }
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.auth.loggedInUser,
    isLoading: state.loading.isLoading,
    recipes: state.programsRecipes.recipes,
    programs: state.programsRecipes.programs
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);