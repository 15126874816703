const defaultState = {
    accessToken: '',
    loggedInUser: {}
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                accessToken: action.token
            };

        case 'SET_USER_ON_LOGIN':
            return {
                ...state,
                loggedInUser: action.user
            };    
        
        case 'LOGOUT':
            return defaultState;  
            
        default:
            return state;
    }
}