import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, CircularProgress, Paper, Card, CardContent} from '@material-ui/core';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        },   
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    MuiCardContentroot: {
        display:'flex', 
        alignItems:'center', 
        '&:last-child' : {
            padding:'8px 8px 8px 16px',
        },
        [theme.breakpoints.down("sm")]: {
            padding:'8px 8px 8px 16px',
        },
    },
    heading : {
        fontSize:'24px',
        fontWeight:'500',
        marginBottom: '0',
        [theme.breakpoints.down("sm")]: {
            fontSize:'15px',
            fontWeight:'500',
            marginBottom: '0',
        },
    },
    loading: {
        position:'absolute',
        left:'48%',
        right:'50%',
        top:'20%'
    },
    wavingHandImage : {
        width: '60px', 
        height: "60px",
        verticalAlign:'text-bottom',
        marginLeft:'-8px',
        [theme.breakpoints.down("sm")]: {
            width: '45px', 
            height: "45px",
            verticalAlign:'text-bottom',
        },
     },
     noProgramImage : {
         width:'90%',
        [theme.breakpoints.down('sm')]: {
           width:'80%'
        },
     }
}));

const NoHomePage = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.mainContent}>
             <Grid item style={{marginTop:'56px'}}> 
                { props.isLoading ? 
                    <CircularProgress size={35} className={classes.loading} color='primary'/> 
                    :
                    <Grid item container direction="column" spacing={4}>
                        <Grid item xs={12} style={{marginTop:'16px'}}>
                            <Card>
                                <CardContent classes={{
                                    root: classes.MuiCardContentroot
                                }}>
                                    <img src="/images/waving_hand.jpg" className={classes.wavingHandImage}/>
                                    <Typography className={classes.heading}> 
                                       Hi <b>{props.loggedInUser?.firstname + " " + props.loggedInUser?.lastname}, </b> 
                                        Welcome to EatWell
                                    </Typography>
                                </CardContent>
                            </Card> 
                        </Grid>

                        <Grid item style={{display:'flex', justifyContent:'center'}}>
                            <img src="/images/no-program-found.svg" className={classes.noProgramImage}/>
                        </Grid>    
                    </Grid>
                }  
              </Grid>             
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.auth.loggedInUser,
    isLoading: state.loading.isLoading,
});

export default connect(mapStateToProps)(NoHomePage);