import React, {useState} from 'react';
import {Drawer, AppBar, Toolbar, Grid, Typography, TextField, IconButton, Button, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import {connect} from 'react-redux';
import { database } from '../firebase/firebase';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import {getAuth} from 'firebase/auth';
import { setUser } from '../actions/authUser';

const useStyles = makeStyles((theme) => ({
    MuiDrawerpaper : {
        width: '450px !important',
    // top: 49px !important;
        height: '100% !important',
        background: theme.palette.primary.light,
        [theme.breakpoints.down('sm')] : {
            width: '100% !important',
            height: '100% !important',
        }
    },
    appBar: {
        background: "#FFFFFF",
        height: "45px",
        color: "#202020",
        borderBottom:'1px solid #ccc'
    },
    drawerBody : {
        padding:'12px 16px 16px 16px'
    },
    buttonContainer : {
        margin:'16px 0px 16px -4px'
    },
    buttonStyles : {
        width:'90px',
        padding:'4px',
        borderRadius:'50px'
    },
    noteStyles : {
        background:'#fff',
        padding:'12px',
        marginBottom:'12px',
        borderRadius:'5px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }
}))

const RecipeNotes = (props) => {
    const classes = useStyles();
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [noteIndex, setNoteIndex] = useState('');

    const notesList = props.loggedInUser?.notes;


    const onSaveNoteClick = async () => {
        if(props.loggedInUser !== {}) {
            if(note !== '') {
                setLoading(true);
                const docRef = doc(database, "users", props.loggedInUser?.id);
                const docSnap = await getDoc(docRef);

                //edit note
                if(editNote) {
                    props.loggedInUser.notes[props.recipeObj.id][noteIndex] = note;
                    await updateDoc(docRef, {notes: props.loggedInUser.notes});
                    setNoteIndex('');
                    setEditNote(false);
                    setLoading(false);

                    //update redux
                    const new_obj = { ...props.loggedInUser, notes: props.loggedInUser.notes}
                    props.setUser(new_obj);

                } else {
                    //add note
                    if(docSnap.exists()) {
                        if(props.loggedInUser.notes && props.loggedInUser.notes === {}) {
                            props.loggedInUser.notes[props.recipeObj.id] = [note];
                            await updateDoc(docRef, {notes: props.loggedInUser.notes});
                            setLoading(false);

                            //update redux
                            const new_obj = { ...props.loggedInUser, notes: props.loggedInUser.notes}
                            props.setUser(new_obj);

                        } else {
                            if(props.loggedInUser.notes[props.recipeObj.id] !== undefined) {
                                props.loggedInUser.notes[props.recipeObj.id].push(note);
                                await updateDoc(docRef, {notes: props.loggedInUser.notes});
                                setLoading(false);

                                //update redux
                                const new_obj = { ...props.loggedInUser, notes: props.loggedInUser.notes}
                                props.setUser(new_obj);
                            } else {
                                props.loggedInUser.notes[props.recipeObj.id] = [note];
                                await updateDoc(docRef, {notes: props.loggedInUser.notes});
                                setLoading(false);

                                //update redux
                                const new_obj = { ...props.loggedInUser, notes: props.loggedInUser.notes}
                                props.setUser(new_obj);
                            }
                        }

                    } else {
                        console.log('document not found');
                    }
                }   

                
            }
            setNote('');
        } else {
            console.log('user not logged in');
        }
    }

    const onDeleteNotesClick = async (index) => {
        if(window.confirm('Are you sure you want to delete the note')) {
            const docRef = doc(database, "users", props.loggedInUser?.id);
            const docSnap = await getDoc(docRef);

            props.loggedInUser.notes[props.recipeObj.id].splice(index,1);
            await updateDoc(docRef, {notes: props.loggedInUser.notes});

            //update redux
            const new_obj = { ...props.loggedInUser, notes: props.loggedInUser.notes}
            props.setUser(new_obj);
        }
    }

    return (
        <div>
            <Drawer
                open={props.openDrawer}
                onClose={props.handleDrawerClose}
                anchor="right"
                classes={{
                    paper: classes.MuiDrawerpaper
                }}>
                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar style={{ minHeight: "40px", padding: "0px 16px" }}>
                        <Typography
                            variant="body1"
                            style={{ fontWeight: "600", marginRight: "auto" }}
                            >
                                {props.recipeObj?.nameOfDish}
                            </Typography>

                            <IconButton
                                style={{ color: "#ccc", marginRight: "-16px" }}
                                onClick={() => {
                                    props.handleDrawerClose();
                                }}
                            >
                            <HighlightOffIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                    <Grid item className={classes.drawerBody}>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                multiline={true}
                                margin="dense"
                                minRows={3}
                                maxRows={5}
                                style={{background:'#fff'}}
                                placeholder="Add a note..."
                                InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />    
                        </Grid>

                        <Grid item container spacing={1} 
                            className={classes.buttonContainer}
                        >
                            <Grid item>
                                <Button variant="contained"
                                    disableElevation
                                    className={classes.buttonStyles} 
                                    onClick={() => onSaveNoteClick()}
                                    color="secondary">
                                    {loading && <CircularProgress size={15} thickness={4.0} style={{marginRight:'8px', color:'white'}}/>}
                                    Save
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button variant="outlined"
                                    elevation={0}
                                    style={{color:'grey'}}
                                    onClick={() => {
                                        props.handleDrawerClose();
                                    }}
                                    className={classes.buttonStyles}>
                                    Cancel
                                </Button>   
                            </Grid>           
                        </Grid> 

                        <Grid item container direction="column">
                                {
                                    notesList[props.recipeObj?.id] !== undefined ? notesList[props.recipeObj?.id].map((str, index) => {
                                        return <Grid item container
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    className={classes.noteStyles} 
                                                    key={index}>
                                                  <Grid item>
                                                    <Typography variant="body2">
                                                        {str}
                                                    </Typography>
                                                  </Grid>

                                                  <Grid item>
                                                    <div style={{display:'flex'}}>
                                                      <IconButton
                                                        onClick={() => {
                                                            setEditNote(true);
                                                            setNoteIndex(index);
                                                            setNote(str);
                                                        }} 
                                                        style={{
                                                          padding:'2px',
                                                          marginRight:'4px',
                                                          color:'grey'
                                                      }}>
                                                        <EditIcon fontSize="small"/>
                                                      </IconButton> 

                                                      <IconButton 
                                                        onClick={() => onDeleteNotesClick(index)}
                                                        style={{
                                                          padding:'2px',
                                                          color:'grey'
                                                      }}>
                                                        <DeleteIcon fontSize="small"/>
                                                      </IconButton>     
                                                    </div>
                                                  </Grid>          
                                               </Grid>
                                    }) : <Grid></Grid>
                                }
                        </Grid>        
                    </Grid>  

            </Drawer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.auth.loggedInUser,
})

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(RecipeNotes);