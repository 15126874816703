import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, CircularProgress} from '@material-ui/core';
import CardComponent from './CardComponent';
import RecipeNotes from './RecipeNotes';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { inFav } from '../util/appUtil';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        flexGrow: 1,
        height:'calc(100vh - 64px)',
        width:'100vw',
        padding:'0px 125px 0px 125px',
        overflow:'auto',
        boxSizing:'border-box',
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            padding:'0px 95px 0px 95px',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding:'0px 30px 0px 30px',
        },
    },
    heading : {
        fontSize:'30px',
        fontWeight:'600',
        marginBottom: '16px'
    },
    loading: {
        position:'absolute',
        left:'48%',
        right:'50%',
        top:'20%'
    },
}))

const Favorites = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const user = props.loggedInUser;
    const favRecipes = props.loggedInUser?.favoriteRecipes;

    const [recipes, setRecipes] = useState([]);
    const [recipeObj, setRecipeObj] = useState(undefined);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }
   
    useEffect(() => {
        if(user) {
            setRecipes(Object.values(props.recipes).filter(recipes => favRecipes?.indexOf(recipes["id"]) !== -1))
        }
    }, []);

    console.log('recipes', recipes);

    return (
        <div className={classes.mainContent}>
           <Grid item md={12} style={{marginTop:'54px'}}> 
               <Typography className={classes.heading}> 
                    Favorites
                </Typography> 

                {props.isLoading ? 
                    <CircularProgress className={classes.loading} color='primary' />
                    :
                    <>
                    {
                        (user.role === 'admin') ? 
                            (<Grid item container
                                spacing={6} 
                                style={{marginTop:'16px'}}>

                            {recipes && recipes.length > 0 ? recipes.sort((a, b) => a.dateUploaded < b.dateUploaded ? 1:-1)
                            .map((favs, index) => {
                                return <Grid item xs={12} sm={12} md={6} lg={6} key={favs.id}>
                                            <CardComponent 
                                                title={favs.nameOfDish}
                                                imageSrc={favs.images}
                                                info={favs.description}
                                                averageRatings={favs.avgRating}
                                                recipeRating={favs.numRatings}
                                                recipeDateUploaded={moment(favs.dateUploaded).format('DD MMMM YYYY')}
                                                handleDrawerOpen={handleDrawerOpen}
                                                facts={favs.recipeFact}
                                                ingredients={favs.descriptionIngredients}
                                                recipeObj={favs}
                                                setRecipeObj={setRecipeObj}
                                                notesList={props.loggedInUser.notes}
                                                isInFavList={inFav(favs.id, user?.favoriteRecipes)}
                                                isFromFavoritePage={true}
                                                onFavClick={() => {
                                                    const idx = recipes.indexOf(favs)
							                        setRecipes(recipes.slice(0, idx).concat(recipes.slice(idx + 1)))
                                                }}
                                                userRating={
                                                    favs.id in user?.ratings ? user?.ratings[favs.id] : 0
                                                }

                                            />
                                        </Grid>
                            }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h1>
                                        No Recipes Found
                                    </h1>
                                </Grid>   
                            }
                        </Grid>)
                        :
                        <Grid item container spacing={6} style={{marginTop:'16px'}}>
                            {recipes && recipes.length > 0 ? recipes.map((favs, index) => {
                                let dateUploaded = props.programs[user.program]?.programRecipes[favs.id];
                                return <Grid item xs={12} sm={12} md={6} lg={6} key={favs.id}>
                                            <CardComponent 
                                                title={favs.nameOfDish}
                                                imageSrc={favs.images}
                                                info={favs.description}
                                                averageRatings={favs.avgRating}
                                                recipeRating={favs.numRatings}
                                                recipeDateUploaded={moment(dateUploaded).format('DD MMM YYYY')}
                                                handleDrawerOpen={handleDrawerOpen}
                                                facts={favs.recipeFact}
                                                ingredients={favs.descriptionIngredients}
                                                recipeObj={favs}
                                                setRecipeObj={setRecipeObj}
                                                notesList={props.loggedInUser.notes}
                                                isInFavList={inFav(favs.id, user?.favoriteRecipes)}
                                                isFromFavoritePage={true}
                                                onFavClick={() => {
                                                    const idx = recipes.indexOf(favs)
							                        setRecipes(recipes.slice(0, idx).concat(recipes.slice(idx + 1)))
                                                }}
                                                userRating={
                                                    favs.id in user?.ratings ? user?.ratings[favs.id] : 0
                                                }

                                            />
                                        </Grid>
                            }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h1>
                                        No Recipes Found
                                    </h1>
                                </Grid>   
                            }
                        </Grid>
                    } 

                    {  props.loggedInUser?.notes &&
                    <RecipeNotes openDrawer={openDrawer}
                        handleDrawerClose={handleDrawerClose}
                        recipeObj={recipeObj}/>  }
                    </>
                }    

            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    loggedInUser: state.auth.loggedInUser,
    recipes: state.programsRecipes.recipes,
    programs: state.programsRecipes.programs,
    loggedInUser: state.auth.loggedInUser,
});

export default connect(mapStateToProps)(Favorites);