import {  createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
    palette: {
        primary: {
          main: '#009f4f',
          light: '#f8f8f8', //light blue for bg
          //contrastText: '#fff',
        },
        secondary: {
          main: '#009f4f'
        },
        error: {
          main: "#ff604f"
        }
      },
      typography:{
        button: {
          textTransform: 'none'
        },
        body1: {
          fontSize: 15,
          fontFamily: "inherit"
        },
        body2: {
          fontSize: 14,
          fontWeight: 400
        },
        subtitle1: {
          fontSize: 13,
        },
        subtitle2: {
          fontSize: 12,
        }
      },

});

theme = responsiveFontSizes(theme);

export default theme;