import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import DashboardPage from '../pages/DashboardPage';
import PrivateRoute from './PrivateRoute';
import {connect} from 'react-redux';
import { Navigate } from 'react-router-dom';
import NoHomePage from '../components/NoHomePage';
import NotFoundPage from '../pages/NotFoundPage';

const AppRouter = (props) => (
    <Router>
        <div>
            <Routes>
                <Route path='*' element={<NotFoundPage/>} />
                <Route path="/activate" element={props.accessToken ? <DashboardPage/>  : <LoginPage />} />
                <Route path="/" element={props.accessToken ? <DashboardPage/>  : <LoginPage />} />
                <Route path="/profile/create" element={props.accessToken ? <DashboardPage/>  : <LoginPage />} />
                <Route path="/profile/makeProfile" element={props.accessToken ? <DashboardPage/> : <LoginPage />} />

                <Route path="/" element={props.accessToken ? <DashboardPage/> : <LoginPage />}/>
                <Route path="/recipes" element={props.accessToken ? <DashboardPage/> : <LoginPage />}/>
                <Route path="/recipes/:slug" element={props.accessToken ? <DashboardPage/> : <LoginPage />}/>
                <Route path="/favorites" element={props.accessToken ? <DashboardPage/> : <LoginPage />}/>
                <Route path="/profile" element={props.accessToken ? <DashboardPage/> : <LoginPage />}/>
                {/* <Route path="/dashboard" element={
                <PrivateRoute auth={props.accessToken}>
                    <DashboardPage/>
                </PrivateRoute>    
            } /> */}
            </Routes>
        </div>
    </Router>
)

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken
})

export default connect(mapStateToProps)(AppRouter);